import React, { ComponentType } from 'react'
import { TextField } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import { MdiReactIconProps } from 'mdi-react'
import { AutocompleteOption } from '@seesignage/seesignage-utils'
import { WrappedFieldMetaProps, WrappedFieldInputProps } from 'redux-form'

interface ReduxSelectWithAutocompleteProps {
  input: WrappedFieldInputProps
  label: string
  meta: WrappedFieldMetaProps
  options: AutocompleteOption[]
  disabled?: boolean
  OptionIcon?: ComponentType<MdiReactIconProps>
  popupIcon?: JSX.Element
}

const ReduxAutocompleteMulti = ({ input, label, ...custom }: ReduxSelectWithAutocompleteProps) => (
  <Autocomplete
    value={input.value || []}
    getOptionLabel={option => option.label}
    onChange={(event: any, value: any) => {
      input.onChange(value)
    }}
    isOptionEqualToValue={(option, value) => option.value === value.value}
    renderInput={params => <TextField variant='standard' {...params} label={label} fullWidth />}
    filterSelectedOptions
    {...custom}
  />
)

export default ReduxAutocompleteMulti
