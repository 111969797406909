import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Tooltip
} from '@mui/material'
import { TFunction } from 'i18next'
import CalendarEditIcon from 'mdi-react/CalendarEditIcon'
import PingIcon from 'mdi-react/CrosshairsGpsIcon'
import DeleteIcon from 'mdi-react/DeleteIcon'
import PlaylistEditIcon from 'mdi-react/PlaylistEditIcon'
import RestartIcon from 'mdi-react/RestartIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import LogsIcon from 'mdi-react/TextIcon'
import PreviewIcon from 'mdi-react/MoviePlayIcon'
import MonitorShareIcon from 'mdi-react/MonitorShareIcon'
import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import { ScreenResponse } from '@seesignage/seesignage-utils'
import { useSelector } from 'react-redux'
import Menu from '../../../components/Menu'
import { CloseDialog, SendScreenCommand, CreatePreviewSessionParams } from '../../../types/actions'
import {
  convertAutocompleteFormFieldValue,
  convertLocationForFormField,
  convertTagsForFormField
} from '../../../utils/conversion'
import Dialog from '../../Dialog'
import CreateScreenForm from '../Forms/CreateScreenForm'
import Logs from '../ScreensTable/Logs'
import DownloadScreenStatsForm from '../Statistics/DownloadScreenStatsForm'
import { OpenDialogButtonType } from '../../../types/dialogs'
import MoveScreenForm from '../Forms/MoveScreenForm'
import { selectChannelById } from '../../../selectors/channels'
import { selectPlaylistById } from '../../../selectors/playlists'
import { validateUserDocumentAccessFromScreensView } from '../../../utils/permissions'
import { selectSelectedEnvironmentId } from '../../../selectors/environments'

const renderEditChannelButton = (
  channelId: string,
  isMobile: boolean,
  t: TFunction,
  environmentId?: string
) => (
  <div key='editChannel'>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/environments/${environmentId}/channels/${channelId}`}>
      {isMobile ? (
        <MenuItem>
          <ListItemIcon>
            <CalendarEditIcon />
          </ListItemIcon>
          <ListItemText primary={t('channels.actions.edit')} />
        </MenuItem>
      ) : (
        <Tooltip disableInteractive title={t('channels.actions.edit')}>
          <IconButton aria-label='edit channel' size='large'>
            <CalendarEditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  </div>
)

const renderEditPlaylistButton = (
  playlistId: string,
  isMobile: boolean,
  t: TFunction,
  environmentId?: string
) => (
  <div key='edit playlist'>
    <Link
      style={{ textDecoration: 'none', color: 'inherit' }}
      to={`/environments/${environmentId}/playlists?playlistId=${playlistId}`}>
      {isMobile ? (
        <MenuItem>
          <ListItemIcon>
            <PlaylistEditIcon />
          </ListItemIcon>
          <ListItemText primary={t('playlists.actions.edit')} />
        </MenuItem>
      ) : (
        <Tooltip disableInteractive title={t('playlists.actions.edit')}>
          <IconButton aria-label='edit playlist' size='large'>
            <PlaylistEditIcon />
          </IconButton>
        </Tooltip>
      )}
    </Link>
  </div>
)

const renderPingButton = (
  sendScreenCommand: SendScreenCommand,
  screenId: string,
  isMobile: boolean,
  t: TFunction
) => (
  <Tooltip disableInteractive key='ping' title={t('screens.pingScreen')}>
    {isMobile ? (
      <MenuItem onClick={() => sendScreenCommand({ screenId, command: 'ping' })}>
        <ListItemIcon>
          <PingIcon />
        </ListItemIcon>
        <ListItemText primary={t('screens.pingScreen')} />
      </MenuItem>
    ) : (
      <IconButton onClick={() => sendScreenCommand({ screenId, command: 'ping' })} size='large'>
        <PingIcon />
      </IconButton>
    )}
  </Tooltip>
)

interface SelectedScreenActionsProps {
  screen: ScreenResponse
  closeDialog: CloseDialog
  deleteScreen: (screenId: string) => void
  updateScreen: (formData: any) => void
  sendScreenCommand: SendScreenCommand
  createPreviewSession: (createPreviewSessionParams: CreatePreviewSessionParams) => void
  isMobile: boolean
  hasAdminAccess: boolean
  isSystemAdmin: boolean
  t: TFunction
}

const SelectedScreenActions: React.FC<SelectedScreenActionsProps> = ({
  screen: {
    screenId,
    environmentId,
    name,
    productNumber,
    rotation,
    type,
    defaultMedia,
    location,
    playlistId,
    playlistPriority,
    listId,
    channelIds,
    tags,
    permissionTags,
    statisticsEnabled,
    identifier,
    locationId,
    billable,
    configs,
    pdaConfigs,
    isTest,
    showBarcode,
    syncPlay,
    content,
    minItems,
    notes,
    optimization
  },
  closeDialog,
  deleteScreen,
  updateScreen,
  sendScreenCommand,
  createPreviewSession,
  isMobile,
  hasAdminAccess,
  isSystemAdmin,
  t
}) => {
  const channelId = channelIds ? channelIds[0].channelId : undefined
  const channel = useSelector(selectChannelById(channelId))
  const playlist = useSelector(selectPlaylistById(playlistId))
  const selectedEnvironmentId = useSelector(selectSelectedEnvironmentId)

  const dateNow = new Date().setHours(12, 0, 0, 0)
  const dialogButtonType = isMobile ? OpenDialogButtonType.menuItem : undefined
  const actions = [
    <div key='preview'>
      <Tooltip disableInteractive title={t('screens.preview.title')}>
        {isMobile ? (
          <MenuItem onClick={() => createPreviewSession({ screenId })}>
            <ListItemIcon>
              <PreviewIcon />
            </ListItemIcon>
            <ListItemText primary={t('screens.preview.title')} />
          </MenuItem>
        ) : (
          <IconButton onClick={() => createPreviewSession({ screenId })} size='large'>
            <PreviewIcon />
          </IconButton>
        )}
      </Tooltip>
    </div>
  ]
  let otherActions: JSX.Element[] = []

  if (
    channel &&
    selectedEnvironmentId &&
    validateUserDocumentAccessFromScreensView(channel, selectedEnvironmentId)
  ) {
    actions.push(renderEditChannelButton(channel.channelId, isMobile, t, environmentId))
  }
  if (
    playlist &&
    selectedEnvironmentId &&
    validateUserDocumentAccessFromScreensView(playlist, selectedEnvironmentId)
  ) {
    actions.push(renderEditPlaylistButton(playlist.playlistId, isMobile, t, environmentId))
  }

  if (hasAdminAccess) {
    otherActions = [
      <div key='restart'>
        <Dialog
          dialogId='RestartScreenDialog'
          title={`${t('screens.restartScreen')} (${name})`}
          ButtonIcon={RestartIcon}
          buttonLabel={isMobile ? t('screens.restartScreen') : undefined}
          openDialogButtonType={dialogButtonType}
          tooltipTitle={t('screens.restartScreen')}
          Content={
            <Fragment>
              <DialogContent>
                <DialogContentText>{t('screens.restartConfirm', { name })}</DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => closeDialog('RestartScreenDialog')} color='primary'>
                  {t('general.disagree')}
                </Button>
                <Button
                  onClick={() => {
                    sendScreenCommand({ screenId, command: 'restart' })
                    closeDialog('RestartScreenDialog')
                  }}
                  color='primary'
                  autoFocus>
                  {t('general.agree')}
                </Button>
              </DialogActions>
            </Fragment>
          }
        />
      </div>,
      renderPingButton(sendScreenCommand, screenId, isMobile, t),
      <div key='logs'>
        <Dialog
          dialogId='ScreenLogs'
          title={`${t('screens.logsSince')} (${name})`}
          buttonLabel={isMobile ? t('screens.showLogs') : undefined}
          openDialogButtonType={dialogButtonType}
          ButtonIcon={LogsIcon}
          maxWidth='lg'
          fullScreen
          tooltipTitle={t('screens.showLogs')}
          Content={
            <Logs
              closeDialog={closeDialog}
              dialogId='ScreenLogs'
              screenId={screenId}
              environmentId={environmentId}
            />
          }
        />
      </div>,
      ...(statisticsEnabled
        ? [
            <div key='stats'>
              <Dialog
                dialogId={`ScreenStatsDialog.${screenId}`}
                title={t('screens.fetchStats', { name })}
                openDialogButtonType={dialogButtonType}
                buttonLabel={isMobile ? t('screens.fetchStats') : undefined}
                ButtonIcon={ChartLineIcon}
                tooltipTitle={t('screens.fetchStatsTooltip')}
                Content={
                  <DownloadScreenStatsForm
                    closeDialog={closeDialog}
                    dialogId={`ScreenStatsDialog.${screenId}`}
                    initialValues={{
                      screenId,
                      screenName: name,
                      startDate: dateNow,
                      endDate: dateNow
                    }}
                  />
                }
              />
            </div>
          ]
        : []),
      <div key='update'>
        <Dialog
          dialogId={`UpdateScreenDialog.${screenId}`}
          title={`${t('screens.update')} (${name})`}
          ButtonIcon={SettingsIcon}
          buttonLabel={isMobile ? t('screens.update') : undefined}
          openDialogButtonType={dialogButtonType}
          tooltipTitle={t('screens.update')}
          maxWidth='md'
          Content={
            <CreateScreenForm
              dialogId={`UpdateScreenDialog.${screenId}`}
              submitButtonLabel={t('general.update')}
              submitAction={updateScreen}
              closeDialog={closeDialog}
              initialValues={{
                name,
                productNumber,
                rotation,
                type,
                defaultMedia: defaultMedia?.key,
                location: convertLocationForFormField(location),
                playlistId: convertAutocompleteFormFieldValue(playlistId),
                playlistPriority,
                listId: convertAutocompleteFormFieldValue(listId),
                channelIds,
                tags: convertTagsForFormField(tags),
                permissionTags: convertTagsForFormField(permissionTags),
                statisticsEnabled,
                identifier,
                locationId,
                billable,
                configs,
                pdaConfigs,
                isTest,
                showBarcode,
                content,
                syncPlay,
                minItems: convertAutocompleteFormFieldValue(minItems),
                notes,
                optimization
              }}
            />
          }
        />
      </div>
    ]
  }

  const toolbarActions = [
    ...actions,
    ...otherActions,
    ...(isSystemAdmin
      ? [
          <div key='move'>
            <Dialog
              dialogId='MoveScreenDialog'
              title={t('screens.move')}
              ButtonIcon={MonitorShareIcon}
              buttonLabel={isMobile ? t('screens.move') : undefined}
              openDialogButtonType={dialogButtonType}
              tooltipTitle={t('screens.move')}
              Content={<MoveScreenForm dialogId='MoveScreenDialog' initialValues={{ screenId }} />}
            />
          </div>,
          <div key='delete'>
            <Dialog
              dialogId='RemoveScreenDialog'
              title={`${t('screens.remove')} (${name})`}
              ButtonIcon={DeleteIcon}
              buttonLabel={isMobile ? t('screens.remove') : undefined}
              openDialogButtonType={dialogButtonType}
              tooltipTitle={t('screens.remove')}
              Content={
                <Fragment>
                  <DialogContent>
                    <DialogContentText>{t('screens.deleteConfirm', { name })}</DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => closeDialog('RemoveScreenDialog')} color='primary'>
                      {t('general.cancel')}
                    </Button>
                    <Button onClick={() => deleteScreen(screenId)} color='secondary' autoFocus>
                      {t('general.delete')}
                    </Button>
                  </DialogActions>
                </Fragment>
              }
            />
          </div>
        ]
      : [])
  ]
  return (
    <Fragment>
      {isMobile ? (
        <Menu tooltipTitle={t('screens.screenOptions')} items={toolbarActions} />
      ) : (
        toolbarActions
      )}
    </Fragment>
  )
}

export default SelectedScreenActions
