import React, { Fragment } from 'react'
import { AppBar, Toolbar, Typography, Tabs, Tab } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { toolbarStyles } from '../../styles/common'
import ScreensWarnings from './ScreensWarnings'
import Users from './Users'

const useStyles = makeStyles(toolbarStyles)

enum TabSection {
  screens = 0,
  users = 1
}

interface TabPanelProps {
  children?: React.ReactNode
  index: TabSection
  value: TabSection
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props
  return (
    value === index && (
      <div
        role='tabpanel'
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {children}
      </div>
    )
  )
}

function a11yProps(index: TabSection) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const Dashboard = () => {
  const classes = useStyles()
  const [value, setValue] = React.useState<TabSection>(TabSection.screens)

  const handleChange = (_event: React.SyntheticEvent, newValue: TabSection) => {
    setValue(newValue)
  }
  return (
    <Fragment>
      <AppBar position='static' color='default'>
        <Toolbar>
          <Typography variant='h6'>Dashboard</Typography>
        </Toolbar>
      </AppBar>
      <AppBar className={classes.appBar} position='static' color='default'>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Screens' {...a11yProps(TabSection.screens)} sx={{ paddingY: '20px' }} />
          <Tab label='Users' {...a11yProps(TabSection.users)} />
        </Tabs>
      </AppBar>
      <CustomTabPanel value={value} index={TabSection.screens}>
        <ScreensWarnings />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={TabSection.users}>
        <Users />
      </CustomTabPanel>
    </Fragment>
  )
}

export default Dashboard
