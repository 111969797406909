import { createSelector } from 'reselect'
import { Channel, AutocompleteOption } from '@seesignage/seesignage-utils'
import { StateInterface } from '../types/states'
import { ScreenChannelOption } from '../types/screens'

const selectChannelsDomain = (state: StateInterface) => state.channels

const selectChannels = createSelector(selectChannelsDomain, channels => channels.channels)

const selectParentChannels = createSelector(selectChannelsDomain, domain => domain.parentChannels)

const selectAllChannels = createSelector(
  selectChannels,
  selectParentChannels,
  (channels, parentChannels) => ({ ...channels, ...parentChannels })
)

const selectAreChannelsLoading = createSelector(
  selectChannelsDomain,
  channels => channels.isLoading
)

const selectChannelsAsArray = createSelector(selectChannels, channels => Object.values(channels))

const selectAllChannelsAsArray = createSelector(selectAllChannels, channels =>
  Object.values(channels)
)

const selectChannelsByAccessAsArray = createSelector(selectChannelsAsArray, channels =>
  channels.filter(channel => channel.userHasAccess)
)

const selectChannelById = (channelId?: string | null) =>
  createSelector(selectAllChannels, channels =>
    channels && channelId ? channels[channelId] : undefined
  )

const selectSelectedCalendarEvent = createSelector(
  selectChannelsDomain,
  channels => channels.selectedCalendarEvent
)

const selectIsEditChannelItemOpen = createSelector(
  selectChannelsDomain,
  channels => channels.editChannelItemOpen
)

const selectChannelItemById = (channelId?: string, selectedItemId?: string) =>
  createSelector(selectChannels, channels => {
    const channel = channelId ? channels[channelId] : undefined
    const item = channel ? channel.items.find(({ itemId }) => itemId === selectedItemId) : undefined
    return item
  })

const selectChannelsAsScreenChannels = (
  channels: Channel[],
  environmentId?: string
): ScreenChannelOption[] =>
  channels.map(({ channelId, environmentId: channelEnvironmentId, name }) => ({
    channelId,
    environmentId: channelEnvironmentId,
    name,
    isParent: environmentId !== channelEnvironmentId
  }))

const selectChannelsTagsArray = createSelector(selectChannelsAsArray, channels => {
  const arr = channels
    .reduce((tagsArray: string[][], { tags }) => {
      if (tags) {
        tagsArray.push(tags)
      }
      return tagsArray
    }, [])
    .flat(1)
  return [...new Set(arr)]
})

const selectChannelsTagsAsOptions = createSelector(selectChannelsTagsArray, tags =>
  tags.map(
    tag =>
      ({
        value: tag,
        label: tag
      } as AutocompleteOption)
  )
)

export {
  selectChannelsDomain,
  selectChannels,
  selectAreChannelsLoading,
  selectChannelsAsArray,
  selectAllChannelsAsArray,
  selectChannelsByAccessAsArray,
  selectChannelById,
  selectSelectedCalendarEvent,
  selectIsEditChannelItemOpen,
  selectChannelItemById,
  selectChannelsAsScreenChannels,
  selectChannelsTagsAsOptions
}
