import {
  ScreenResponse,
  ScreenStatus,
  Status,
  ScreenWarningType,
  Configs,
  PdaConfigs,
  ScreenContent,
  ScreenOptimization
} from '@seesignage/seesignage-utils'
import AlarmOffIcon from 'mdi-react/AlarmOffIcon'
import SignalLowIcon from 'mdi-react/SignalCellular1Icon'
import SignalOff from 'mdi-react/SignalOffIcon'
import ClockOutlineIcon from 'mdi-react/ClockOutlineIcon'
import TimerOutlineIcon from 'mdi-react/TimerOutlineIcon'
import AlertIcon from 'mdi-react/AlertIcon'
import MessageAlertIcon from 'mdi-react/MessageAlertIcon'
import AlphaVBoxIcon from 'mdi-react/AlphaVBoxIcon'
import { SortDirection, SortByValue } from '../types/sortings'
import { BatchUpdateScreenSelectedProperties, TimeRange } from '../types/screens'
import {
  compareScreensByAvailableCapacity,
  compareStringsNested,
  compareBySortType
} from './sorting'
import { getBooleanPropertyValue } from './forms'

const countDaysSinceLastContact = (statuses: Status[], currentTimeMs: number) => {
  const lastStatusItem = statuses.slice(-1)[0]
  if (lastStatusItem) {
    const updatedAt = lastStatusItem.updatedAt
    if (updatedAt) {
      return Math.floor((currentTimeMs - updatedAt) / 1000 / 60 / 60 / 24)
    }
  }
}

interface ScreensByStatuses {
  notLinked: ScreenResponse[]
  online: ScreenResponse[]
  offline: ScreenResponse[]
  standby: ScreenResponse[]
}

const getScreensByStatuses = (screens: ScreenResponse[]) =>
  screens.reduce(
    (byStatus: ScreensByStatuses, screen) => {
      switch (screen.status) {
        case ScreenStatus.notLinked:
          byStatus.notLinked.push(screen)
          break
        case ScreenStatus.offline:
          byStatus.offline.push(screen)
          break
        case ScreenStatus.online:
          byStatus.online.push(screen)
          break
        case ScreenStatus.standby:
          byStatus.standby.push(screen)
          break
      }
      return byStatus
    },
    { notLinked: [], online: [], offline: [], standby: [] }
  )

/**
 * Generate screen statuses to datapoints fot scatter chart
 */
const generateStatusesToDataPoints = (statuses: Status[] | undefined) =>
  statuses
    ? statuses.map((status: Status) => ({
        value: 1,
        date: status.updatedAt,
        // NOTE: LG does not support signal strength
        isEthernetConnected: status.ethernetNetwork?.status === 'CONNECTED',
        isWifiOn: status.wifiNetwork?.status === 'ON',
        signalStrength: status.wifiNetwork?.signalStrength,
        ssid: status.wifiNetwork?.ssid
      }))
    : []

const WarningIcons = {
  onOffTimerMissing: AlarmOffIcon,
  shouldBeOnline: TimerOutlineIcon,
  shutdownTooEarly: ClockOutlineIcon,
  tooLongOffline: SignalOff,
  poorWifi: SignalLowIcon,
  lowStorageSpace: AlertIcon,
  noContent: MessageAlertIcon,
  oldVersion: AlphaVBoxIcon
}

const getScreentWarningIcon = (warningType: ScreenWarningType) => WarningIcons[warningType]

/**
 * Sort screens by table columns order
 * @param screens
 * @param orderBy such as 'name' or 'productNumber'
 * @param order 'asc' or 'desc'
 */
const sortScreensByTableColumnsOrder = (
  screens: ScreenResponse[],
  orderBy: string,
  order: SortDirection
) => {
  if (orderBy === 'availableCapacity') {
    const { screensWithStorage, screensWithoutStorage } = screens.reduce(
      ({ screensWithStorage, screensWithoutStorage }: any, screen) => {
        if (screen?.storage?.availableCapacity) {
          screensWithStorage.push(screen)
        } else {
          screensWithoutStorage.push(screen)
        }
        return {
          screensWithStorage,
          screensWithoutStorage
        }
      },
      {
        screensWithStorage: [],
        screensWithoutStorage: []
      }
    )
    const screensSorted = screensWithStorage.sort(compareScreensByAvailableCapacity(order))
    return [...screensSorted, ...screensWithoutStorage]
  } else if (orderBy === 'lastContact') {
    return screens.sort(compareBySortType(SortByValue.lastOnline, order))
  }
  return screens.sort(compareStringsNested(orderBy, order))
}

/**
 * Get screen configs that needs to be updated
 * @param properties
 * @param configs
 */
const getBatchConfigs = (properties: BatchUpdateScreenSelectedProperties, configs?: Configs) => {
  const updatedConfigs: Configs = {}
  if (properties.safetyLock) {
    updatedConfigs.security = {
      safetyLock: getBooleanPropertyValue(configs?.security?.safetyLock)
    }
  }
  if (properties.ecoSensor) {
    updatedConfigs.ecoSensor = {
      isOn: getBooleanPropertyValue(configs?.ecoSensor?.isOn)
    }
  }
  if (configs?.onOffTimer && properties.onOffTimer) {
    updatedConfigs.onOffTimer = configs.onOffTimer
  }
  if (properties.volume) {
    if (typeof configs?.volume === 'number') {
      updatedConfigs.volume = configs.volume
    } else {
      // volume is required. Set to zero by default if not given.
      updatedConfigs.volume = 0
    }
  }
  // return undefined when none properties were changed, so backend won't update configs
  return Object.keys(updatedConfigs).length > 0 ? updatedConfigs : undefined
}

/**
 * Get screen pdaConfigs that needs to be updated
 * @param properties
 * @param pdaConfigs
 */
const getBatchPdaConfigs = (
  properties: BatchUpdateScreenSelectedProperties,
  pdaConfigs?: PdaConfigs
) => {
  const updatedPdaConfigs: PdaConfigs = {}
  if (properties.allowOnlyEditLists) {
    updatedPdaConfigs.allowOnlyEditLists = getBooleanPropertyValue(pdaConfigs?.allowOnlyEditLists)
  }
  // return undefined when none properties were changed, so backend won't update pdaCondigs
  return Object.keys(updatedPdaConfigs).length > 0 ? updatedPdaConfigs : undefined
}

const getBatchContent = (
  properties: BatchUpdateScreenSelectedProperties,
  content?: ScreenContent
) => {
  const updatedScreenContent: ScreenContent = {}
  if (properties.interleave) {
    updatedScreenContent.interleave = getBooleanPropertyValue(content?.interleave)
  }
  // return undefined when none properties were changed, so backend won't update pdaCondigs
  return Object.keys(updatedScreenContent).length > 0 ? updatedScreenContent : undefined
}

const getBatchOptimization = (
  properties: BatchUpdateScreenSelectedProperties,
  optimization?: ScreenOptimization
) => {
  const updatedScreenOptimization: ScreenOptimization = {}
  if (properties.reduceServerLoadIot) {
    updatedScreenOptimization.reduceServerLoadIot = getBooleanPropertyValue(
      optimization?.reduceServerLoadIot
    )
  }
  // return undefined when none properties were changed, so backend won't update pdaCondigs
  return Object.keys(updatedScreenOptimization).length > 0 ? updatedScreenOptimization : undefined
}

const weekInMilliseconds = 604800000
const threeDaysInMilliseconds = 259200000
const oneDayInMilliseconds = 86400000

const getTimeRangeStartTime = (timeRange?: TimeRange) => {
  if (timeRange) {
    const now = Date.now()
    switch (timeRange) {
      case TimeRange.oneDay:
        return now - oneDayInMilliseconds
      case TimeRange.threeDays:
        return now - threeDaysInMilliseconds
      case TimeRange.week:
        return now - weekInMilliseconds
    }
  }
  return -1
}

const getStatusesForTimeRange = (statuses: Status[], timeRange: TimeRange) => {
  const timeRangeStart = getTimeRangeStartTime(timeRange)
  return statuses.filter(status => (status?.updatedAt ? status.updatedAt >= timeRangeStart : false))
}

export {
  getScreensByStatuses,
  countDaysSinceLastContact,
  generateStatusesToDataPoints,
  getScreentWarningIcon,
  sortScreensByTableColumnsOrder,
  getBatchConfigs,
  getBatchPdaConfigs,
  getBatchContent,
  getBatchOptimization,
  getStatusesForTimeRange,
  getTimeRangeStartTime
}
