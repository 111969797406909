import React, { Fragment } from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { Route, Switch, Redirect } from 'react-router'
import * as H from 'history'
import Navigation from './containers/Navigation'
import Login from './containers/Authentication/Login'
import Signup from './containers/Authentication/Signup'
import Profile from './containers/Profile'
import Screens from './containers/Screens'
import MediaBrowser from './containers/MediaBrowser'
import Playlists from './containers/Playlists'
import Connections from './containers/Connections'
import Lists from './containers/Lists'
import Channels from './containers/Channels'
import Templates from './containers/Templates'
import EditTemplate from './containers/Templates/EditTemplate/EditTemplate'
import HomePage from './containers/Home/HomePage'
import EditLunchList from './containers/Lists/EditList/LunchList/EditLunchList'
import EditList from './containers/Lists/EditList/RetailList/EditList'
import Products from './containers/Products/Products'
import EditMiscList from './containers/Lists/EditList/MiscList/EditMiscList'
import EditChannel from './containers/Channels/EditChannel'
import Customers from './containers/Customers'
import ContentEditor from './containers/Contents/ContentEditor'
import Infopages from './containers/Infopages'
import EditInfopage from './containers/Infopages/EditInfopage/EditInfopage'
import { ForgotPassword } from './containers/Authentication/ForgotPassword'
import EditEnvironment from './containers/Environments/EditEnvironment'
import Dashboard from './containers/Dashboard'
import Campaigns from './containers/Campaigns'
import ErrorLogs from './containers/ErrorLogs'

const EmptyComponent = () => <Fragment />

const Routes = () => (
  <Switch>
    <Route exact path='/home' component={HomePage} />
    <Route exact path='/login' component={Login} />
    <Route exact path='/signup' component={Signup} />
    <Route exact path='/forgotpassword/:type' component={ForgotPassword} />
    <Route exact path='/profile' component={Profile} />
    <Route exact path='/connections' component={Connections} />
    <Route exact path='/environments/:environmentId/campaigns' component={Campaigns} />
    <Route exact path='/environments/:environmentId/screens' component={Screens} />
    <Route exact path='/environments/:environmentId/connections' component={Connections} />
    <Route exact path='/environments/:environmentId/playlists' component={Playlists} />
    <Route exact path='/environments/:environmentId/lists' component={Lists} />
    <Route exact path='/environments/:environmentId/miscLists/:listId' component={EditMiscList} />
    <Route exact path='/environments/:environmentId/lists/:listId' component={EditList} />
    <Route exact path='/environments/:environmentId/lunchLists/:listId' component={EditLunchList} />
    <Route exact path='/environments/:environmentId/channels' component={Channels} />
    <Route exact path='/environments/:environmentId/channels/:channelId' component={EditChannel} />
    <Route exact path='/environments/:environmentId/templates' component={Templates} />
    <Route
      exact
      path='/environments/:environmentId/templates/:templateId'
      component={EditTemplate}
    />
    <Route exact path='/integrations' component={Customers} />
    <Route exact path='/environments/:environmentId/media' component={MediaBrowser} />
    <Route
      exact
      path='/environments/:environmentId/media/folders/:folderId'
      component={MediaBrowser}
    />
    <Route exact path='/environments/:environmentId/products' component={Products} />
    <Route
      exact
      path='/environments/:environmentId/contents/:contentId'
      component={ContentEditor}
    />
    <Route exact path='/infopages' component={Infopages} />
    <Route exact path='/environments/:environmentId/infopages' component={Infopages} />
    <Route
      exact
      path='/environments/:environmentId/infopages/:infopageId'
      component={EditInfopage}
    />
    <Route exact path='/environments/:environmentId/edit' component={EditEnvironment} />
    <Route exact path='/dashboard' component={Dashboard} />
    <Route exact path='/errorLogs' component={ErrorLogs} />
    <Route
      exact
      path='/s/:screenCode'
      component={
        // '/s/:screenCode' path needs to be specified as valid path. Otherwise, Router redirects to '/home'
        EmptyComponent
      }
    />
    <Route>
      <Redirect to='/home' />
    </Route>
  </Switch>
)

const AppRouter = ({ history }: { history: H.History<any> }) => (
  <ConnectedRouter history={history}>
    <Fragment>
      <Navigation Routes={Routes} />
    </Fragment>
  </ConnectedRouter>
)
export default AppRouter
