import {
  AppBar,
  Drawer,
  IconButton,
  LinearProgress,
  MenuItem,
  Toolbar,
  Typography,
  ListItemIcon,
  ListItemText
} from '@mui/material'
import { styled, useTheme } from '@mui/styles'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React, { useState, useEffect, Fragment, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import AccountIcon from 'mdi-react/AccountCircleIcon'
import DashboardIcon from 'mdi-react/ViewDashboardIcon'
import CalendarMultipleIcon from 'mdi-react/CalendarMultipleIcon'
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon'
import ChevronRightIcon from 'mdi-react/ChevronRightIcon'
import SettingsIcon from 'mdi-react/SettingsIcon'
import MediaIcon from 'mdi-react/FolderMultipleImageIcon'
import LogoutIcon from 'mdi-react/LogoutIcon'
import MenuIcon from 'mdi-react/MenuIcon'
import MessageAlertIcon from 'mdi-react/MessageAlertIcon'
import MonitorsIcon from 'mdi-react/MonitorMultipleIcon'
import PaletteAdvancedIcon from 'mdi-react/PaletteAdvancedIcon'
import PlaylistPlayIcon from 'mdi-react/PlaylistPlayIcon'
import ShoppingIcon from 'mdi-react/ShoppingIcon'
import CloudIcon from 'mdi-react/CloudIcon'
import TableColumnIcon from 'mdi-react/TableColumnIcon'
import TagIcon from 'mdi-react/TagIcon'
import NotificationsIcon from 'mdi-react/BellIcon'
import { useTranslation } from 'react-i18next'
import QuestionMarkCircleIcon from 'mdi-react/QuestionMarkCircleIcon'
import BookInformationVariantIcon from 'mdi-react/BookInformationVariantIcon'
import {
  openDialog as openDialogAction,
  closeDialog as closeDialogAction
} from '../../actions/dialogs'
import { initializeSession as initializeSessionAction } from '../../actions/users'
import menuSignage from '../../images/SeeSignage-logo-horizontal-on-black-500px.png'
import { selectAreProductsEditable } from '../../selectors/customers'
import {
  selectIsUserDeviceMobile,
  selectUser,
  selectUserIsAuthenticated,
  selectUserIsLoadingSession
} from '../../selectors/users'
import { getMenuOpenState, setMenuOpenState } from '../../services/localStorage/menu'
import Dialog from '../Dialog'
import FeedbackForm from '../Feedback/FeedbackForm'
import ChangeEnvironment from '../../components/Environments'
import Support from '../Support'
import {
  selectEnvironmentTemplateTypes,
  selectCurrentEnvironmentFeatures,
  selectEnvironmentsAsArray
} from '../../selectors/environments'
import SubscriptionsForm from '../Subscriptions'
import MenuDivider from './MenuDivider'
import NavigationMenuItems from './NavigationMenuItems'

const drawerWidth = 240

const useNavigationStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex'
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    backgroundColor: '#000'
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginLeft: theme.spacing(1)
  },
  menuItem: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  menuIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
    justifyContent: 'center'
  },
  hide: {
    display: 'none'
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(8)
    }
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  content: {
    flexGrow: 1,
    overflowX: 'auto',
    height: '100vh',
    backgroundColor: '#fafafa'
  },
  button: {
    color: 'white',
    textDecoration: 'none'
  },
  flex: {
    flex: 1
  },
  logo: {
    marginLeft: 10
  },
  link: {
    textDecoration: 'none'
  }
}))

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

interface MenuItem {
  path: string
  label: string
  icon: JSX.Element
}

const schedulingItems = [
  {
    path: '/channels',
    label: 'navigation.channels',
    icon: <CalendarMultipleIcon />
  },
  {
    path: '/campaigns',
    label: 'navigation.campaigns',
    icon: <TagIcon />
  },
  {
    path: '/screens',
    label: 'navigation.screens',
    icon: <MonitorsIcon />
  }
]

const contentItems = [
  {
    path: '/playlists',
    label: 'navigation.playlists',
    icon: <PlaylistPlayIcon />
  },
  {
    path: '/infopages',
    label: 'navigation.infopages',
    icon: <BookInformationVariantIcon />
  },
  {
    path: '/products',
    label: 'navigation.products',
    icon: <ShoppingIcon />
  },
  {
    path: '/lists',
    label: 'navigation.lists',
    icon: <TableColumnIcon />
  },
  {
    path: '/media',
    label: 'navigation.media',
    icon: <MediaIcon />
  },
  {
    path: '/templates',
    label: 'navigation.templates',
    icon: <PaletteAdvancedIcon />
  }
]

const userItems = [
  {
    path: '/profile',
    label: 'navigation.profile',
    icon: <AccountIcon />
  },
  {
    path: '/dashboard',
    label: 'navigation.dashboard',
    icon: <DashboardIcon />
  },
  {
    path: '/errorLogs',
    label: 'navigation.errorLogs',
    icon: <NotificationsIcon />
  },
  {
    path: '/edit',
    label: 'navigation.environments',
    icon: <SettingsIcon />
  },
  {
    path: '/integrations',
    label: 'navigation.integrations',
    icon: <CloudIcon />
  },
  {
    path: '/logout',
    label: 'navigation.logout',
    icon: <LogoutIcon />
  }
]

interface NavigationProps {
  Routes: () => JSX.Element
}

const Navigation: React.FC<NavigationProps> = ({ Routes }) => {
  const theme = useTheme()
  const { pathname } = useLocation()
  const classes = useNavigationStyles()
  const [t] = useTranslation()

  const dispatch = useDispatch()
  const openDialog = useCallback((dialogId: string) => dispatch(openDialogAction(dialogId)), [
    dispatch
  ])
  const closeDialog = (dialogId: string) => dispatch(closeDialogAction(dialogId))

  const isUserAuthenticated = useSelector(selectUserIsAuthenticated)
  const isLoadingSession = useSelector(selectUserIsLoadingSession)
  const user = useSelector(selectUser)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const hasEditableProducts = useSelector(selectAreProductsEditable)
  const environmentTemplateTypes = useSelector(selectEnvironmentTemplateTypes)
  const environmentFeatures = useSelector(selectCurrentEnvironmentFeatures)
  const environmentsCount = useSelector(selectEnvironmentsAsArray).length

  // handleMountActions
  useEffect(() => {
    dispatch(initializeSessionAction())
  }, [dispatch])
  useEffect(() => {
    if (user && typeof user?.subscriptions?.newsLetter !== 'boolean') {
      openDialog('¨SubscribeToNewsLetter')
    }
  }, [user, openDialog])

  const [isMenuOpen, toggleDrawer] = useState<boolean>(getMenuOpenState())
  const handleToggleDrawer = () => {
    const isOpen = !isMenuOpen
    toggleDrawer(isOpen)
    setMenuOpenState(isOpen)
  }

  return (
    <div className={classes.root}>
      {isUserAuthenticated ? (
        <Fragment>
          <AppBar
            position='absolute'
            className={classNames(classes.appBar, isMenuOpen && classes.appBarShift)}>
            <Toolbar disableGutters={!isMenuOpen}>
              <IconButton
                id='toggle-navigation-drawer-button'
                color='inherit'
                aria-label='open drawer'
                onClick={handleToggleDrawer}
                className={classNames(classes.menuButton, isMenuOpen && classes.hide)}
                size='large'>
                <MenuIcon color='#fff' />
              </IconButton>
              <Typography className={classes.flex}>
                <Link to={'/home'}>
                  <img className={classes.logo} src={menuSignage} alt='SeeSignage' height='30' />
                </Link>
              </Typography>
              {environmentsCount > 1 && <ChangeEnvironment />}
            </Toolbar>
          </AppBar>
          <Drawer
            onClick={isMobile ? handleToggleDrawer : undefined}
            variant={isMobile ? undefined : 'permanent'}
            classes={{
              paper: classNames(classes.drawerPaper, !isMenuOpen && classes.drawerPaperClose)
            }}
            open={isMenuOpen}>
            <DrawerHeader>
              <IconButton onClick={handleToggleDrawer} id='toggle-navigation-drawer-button'>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
              </IconButton>
            </DrawerHeader>
            <MenuDivider
              isMenuOpen={isMenuOpen}
              label={t('navigation.subheaders.contentScheduling')}
            />
            {user && (
              <NavigationMenuItems
                menuItems={schedulingItems}
                currentPath={pathname}
                hasEditableProducts={hasEditableProducts}
                user={user}
                environmentTemplateTypes={environmentTemplateTypes}
                environmentFeatures={environmentFeatures}
                isMenuOpen={isMenuOpen}
              />
            )}
            <MenuDivider
              isMenuOpen={isMenuOpen}
              label={t('navigation.subheaders.screenContents')}
            />
            {user && (
              <NavigationMenuItems
                menuItems={contentItems}
                currentPath={pathname}
                hasEditableProducts={hasEditableProducts}
                user={user}
                environmentTemplateTypes={environmentTemplateTypes}
                environmentFeatures={environmentFeatures}
                isMenuOpen={isMenuOpen}
              />
            )}
            <MenuDivider isMenuOpen={isMenuOpen} label={t('navigation.subheaders.users')} />
            {user && (
              <NavigationMenuItems
                menuItems={userItems}
                currentPath={pathname}
                user={user}
                environmentTemplateTypes={environmentTemplateTypes}
                isMenuOpen={isMenuOpen}
              />
            )}
            <MenuDivider isMenuOpen={isMenuOpen} label={t('navigation.subheaders.support')} />
            <MenuItem onClick={() => openDialog('SupportDialog')} className={classes.menuItem}>
              <ListItemIcon className={classes.menuIcon}>
                <QuestionMarkCircleIcon />
              </ListItemIcon>
              <ListItemText primary={t('navigation.support')} />
            </MenuItem>
            <MenuItem onClick={() => openDialog('FeedbackFormDialog')} className={classes.menuItem}>
              <ListItemIcon className={classes.menuIcon}>
                <MessageAlertIcon />
              </ListItemIcon>
              <ListItemText primary={t('feedback.sendFeedback')} />
            </MenuItem>
          </Drawer>
        </Fragment>
      ) : (
        <AppBar position='absolute' className={classes.appBar}>
          <Toolbar disableGutters>
            <Typography className={classes.flex}>
              <img className={classes.logo} src={menuSignage} alt='SeeSignage' height='30' />
            </Typography>
          </Toolbar>
        </AppBar>
      )}
      <Dialog
        dialogId='¨SubscribeToNewsLetter'
        title={t('subscriptions.title')}
        noOpenDialogButton
        Content={
          <SubscriptionsForm
            t={t}
            closeDialog={() => closeDialog('SubscribeToNewsLetter')}
            initialValues={{ subscriptions: { newsLetter: true } }}
          />
        }
      />
      <Dialog
        dialogId='SupportDialog'
        title={t('navigation.support')}
        noOpenDialogButton
        Content={<Support />}
      />
      <Dialog
        dialogId='FeedbackFormDialog'
        title={t('feedback.sendFeedback')}
        noOpenDialogButton
        Content={<FeedbackForm dialogId={'FeedbackFormDialog'} initialValues={{ rating: 5 }} />}
      />
      <main className={classes.content} id='main-content'>
        <div className={classes.toolbar} />
        {isLoadingSession && <LinearProgress />}
        <Routes />
      </main>
    </div>
  )
}
export default Navigation
