import React from 'react'
import ClearIcon from 'mdi-react/ClearIcon'
import { IconButton } from '@mui/material'
import { WrappedFieldProps } from 'redux-form'
import { MobileDatePicker, PickersActionBarAction } from '@mui/x-date-pickers'
import { convertDatePickerValue } from '../../../utils/forms'

interface ReduxDatePickerProps extends WrappedFieldProps {
  label: string
  dateformat: string
  required?: boolean
  supportClearValue?: boolean
  maxDate?: Date
  minDate?: Date
  shouldDisableDate?: (day: Date) => boolean
  initialFocusedDate?: any
  /** if startOfDay, set date's time to start of the selected day */
  startOfTheDay?: boolean
  /** if endOfTheDay, set date's time to end of the selected day */
  endOfTheDay?: boolean
  /** Close picker dialog on select */
  autoCloseOnSelect?: boolean
}

const ReduxDatePicker: React.FC<ReduxDatePickerProps> = ({
  input: { value, onChange, onBlur, onFocus },
  required,
  label,
  meta: { touched, invalid, error },
  supportClearValue,
  autoCloseOnSelect,
  startOfTheDay,
  endOfTheDay,
  shouldDisableDate,
  ...custom
}) => {
  return (
    <MobileDatePicker
      closeOnSelect={autoCloseOnSelect}
      format='dd/MM/yyyy'
      displayWeekNumber
      shouldDisableDate={shouldDisableDate}
      label={label}
      value={convertDatePickerValue(value)}
      onChange={(newValue: Date | null) => {
        if (startOfTheDay && newValue) {
          newValue.setHours(0, 0, 0)
        } else if (endOfTheDay && newValue) {
          newValue.setHours(23, 59, 59)
        }
        onChange(newValue)
      }}
      slotProps={{
        actionBar: {
          actions: [...(required ? [] : ['clear' as PickersActionBarAction]), 'cancel', 'accept']
        },
        textField: {
          error: invalid && touched,
          helperText: invalid && touched ? error : undefined,
          required,
          variant: 'standard',
          InputProps: {
            endAdornment:
              supportClearValue && value ? (
                <IconButton
                  onClick={e => {
                    e.stopPropagation()
                    onChange(null)
                  }}
                  size='large'>
                  <ClearIcon size={16} />
                </IconButton>
              ) : (
                undefined
              )
          },
          onBlur: () => onBlur(value),
          onFocus: () => onFocus(value)
        }
      }}
      {...custom}
    />
  )
}

export default ReduxDatePicker
