/* eslint-disable @typescript-eslint/camelcase */
export default {
  s3: {
    mediaUploads: {
      REGION: process.env.REACT_APP_S3_MEDIA_UPLOADS_BUCKET_REGION,
      BUCKET: process.env.REACT_APP_S3_MEDIA_UPLOADS_BUCKET
    },
    screenStatistics: {
      REGION: process.env.REACT_APP_S3_SCREEN_STATISTICS_BUCKET_REGION,
      BUCKET: process.env.REACT_APP_S3_SCREEN_STATISTICS_BUCKET
    }
  },
  apiGateway: {
    environments: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/environments`
    },
    screens: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/screens`
    },
    playlists: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/playlists`
    },
    users: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/users`
    },
    media: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/media`
    },
    feedback: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/feedback`
    },
    lists: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/lists`
    },
    products: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/products`
    },
    templates: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/templates`
    },
    customers: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/customers`
    },
    channels: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/channels`
    },
    contents: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/contents`
    },
    infopages: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/infopages`
    },
    campaigns: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/campaigns`
    },
    integrations: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/integrations`
    },
    logs: {
      REGION: process.env.REACT_APP_MAIN_REGION,
      URL: `${process.env.REACT_APP_API_BASE_URL}/logs`
    }
  },
  iot: {
    aws_pubsub_region: process.env.REACT_APP_IOT_REGION,
    aws_pubsub_endpoint: `wss://${process.env.REACT_APP_IOT_URL}/mqtt`
  },
  cognito: {
    REGION: process.env.REACT_APP_MAIN_REGION,
    USER_POOL_ID: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
  },
  pinpoint: {
    REGION: process.env.REACT_APP_PINPOINT_REGION,
    APP_ID: process.env.REACT_APP_PINPOINT_PROJECT_ID
  }
}
