import { toast } from 'react-toastify'
import { SubmissionError } from 'redux-form'
import JSZip from 'jszip'
import { all, takeLatest, take, put, call, select, takeEvery } from 'redux-saga/effects'
import {
  UpdateScreen,
  ScreenResponse,
  Location,
  BatchUpdateScreenProperties,
  ScreenType,
  ScreenIotMessageCommand
} from '@seesignage/seesignage-utils'
import { closeDialog } from '../actions/dialogs'
import Api from '../services/api/screens'
import {
  createScreen,
  createScreenSuccess,
  createScreenFail,
  listScreens,
  listScreensFail,
  listScreensSuccess,
  deleteScreen,
  deleteScreenFail,
  deleteScreenSuccess,
  updateScreen,
  updateScreenSuccess,
  sendScreenCommand,
  sendScreenCommandFail,
  sendScreenCommandSuccess,
  getScreen,
  getScreenFail,
  getScreenSuccess,
  updateScreenFail,
  selectScreen,
  fetchStatsFilesSuccess,
  fetchStatsFilesFail,
  fetchStatsFiles,
  downloadScreensCsv,
  downloadScreensCsvSuccess,
  downloadScreensCsvFail,
  downloadAdminScreensCsv,
  downloadAdminScreensCsvSuccess,
  downloadAdminScreensCsvFail,
  listScreensWithWarnings,
  listScreensWithWarningsFail,
  listScreensWithWarningsSuccess,
  updateScreenFromDashboard,
  updateScreenFromDashboardFail,
  updateScreenFromDashboardSuccess,
  getScreenFromDashboardSuccess,
  getScreenFromDashboardFail,
  getScreenFromDashboard,
  moveScreenSuccess,
  moveScreenFail,
  moveScreen,
  batchUpdateScreensSuccess,
  batchUpdateScreens,
  selectScreenRow
} from '../actions/screens'
import {
  selectScreenById,
  selectScreensAsArray,
  selectScreenByIdDashboard,
  selectSelectedScreenIds
} from '../selectors/screens'
import { selectEnvironmentIdFromPathname } from '../selectors/routing'
import { getLocationForScreen } from '../services/api/googlePlaces'
import i18n from '../translations/i18n'
import { selectSelectedEnvironmentId, selectEnvironmentById } from '../selectors/environments'
import { getEnvironmentSuccess, getEnvironmentFail } from '../actions/environments'
import { generateScreensCsv, downloadCsvFile } from '../utils/csv'
import {
  convertAutoselectValue,
  convertScreenChannelsValue,
  convertAutoselectValueNumber,
  getBooleanPropertyValue
} from '../utils/forms'
import { generateAndDownloadZip } from '../utils/zip'
import {
  handleCreateScreenParams,
  handleFetchStatsFilesParams,
  handleUpdateScreenFromDashboardParams,
  handleMoveScreenParams,
  handleBatchUpdateScreensParams
} from '../types/formData'
import { selectPlaylistById } from '../selectors/playlists'
import {
  getBatchConfigs,
  getBatchContent,
  getBatchOptimization,
  getBatchPdaConfigs
} from '../utils/screens'
import { getMediaTypeFromKey } from '../utils/media'
import { getSelectedEnvironmentId } from './environments'

export function* handleCreateScreen({
  payload: { formData, resolve, reject }
}: handleCreateScreenParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const {
      rotation,
      playlistId,
      playlistPriority,
      listId,
      channelIds: formChannelIds,
      defaultMedia,
      name,
      productNumber,
      location,
      type,
      tags,
      permissionTags,
      statisticsEnabled,
      identifier,
      locationId,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      content,
      minItems,
      notes,
      optimization
    } = formData
    const screen: UpdateScreen = {
      rotation,
      name,
      productNumber,
      channelIds: convertScreenChannelsValue(formChannelIds),
      playlistId: convertAutoselectValue(playlistId),
      listId: convertAutoselectValue(listId),
      type,
      statisticsEnabled,
      identifier,
      locationId,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      content,
      minItems: convertAutoselectValueNumber(minItems),
      notes,
      optimization
    }
    if (screen.playlistId && screen.channelIds) {
      screen.playlistPriority = playlistPriority
    }
    if (screen.playlistId) {
      const { environmentId: playlistEnvironmentId } = yield select(
        selectPlaylistById(screen.playlistId)
      )
      screen.playlistEnvironmentId =
        environmentId !== playlistEnvironmentId ? playlistEnvironmentId : null
    }

    if (tags) {
      const newTags = tags.map(({ label }) => label)
      screen.tags = newTags
    }
    if (permissionTags && permissionTags.length) {
      const newPermissions = permissionTags.map(({ label }) => label)
      screen.permissionTags = newPermissions
    }
    // Can't have both listId and playlistId
    switch (type) {
      case 'media':
        screen.listId = null
        break
      case 'price':
        screen.playlistId = null
        screen.channelIds = null
        break
      default:
        break
    }

    // format default media
    if (defaultMedia) {
      const type = getMediaTypeFromKey(defaultMedia)
      if (type) {
        screen.defaultMedia = {
          key: defaultMedia,
          type
        }
      } else {
        throw new SubmissionError({
          _error: i18n.t('error.screen.invalidDefaultMediaType')
        })
      }
    } else {
      screen.defaultMedia = null
    }

    if (location) {
      const screenLocation: Location = yield call(
        getLocationForScreen,
        location.value,
        location.label
      )
      screen.location = screenLocation
    } else {
      screen.location = null
    }
    const newScreen = yield call(Api.createScreen, environmentId, screen)
    yield put(createScreenSuccess(newScreen))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.screen.somethingWrongCreate')
      })
    )

    yield put(createScreenFail(error.message))
  }
}

export function* handleUpdateScreen({
  payload: { formData, resolve, reject }
}: handleCreateScreenParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const screenIds = yield select(selectSelectedScreenIds)
    const {
      rotation,
      playlistId,
      playlistPriority,
      channelIds: formChannelIds,
      defaultMedia,
      name,
      productNumber,
      location,
      type,
      listId,
      tags,
      permissionTags,
      statisticsEnabled,
      identifier,
      locationId,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      content,
      minItems,
      notes,
      optimization
    } = formData
    const screen: UpdateScreen = {
      rotation,
      name,
      productNumber,
      playlistId: convertAutoselectValue(playlistId),
      type,
      listId: convertAutoselectValue(listId),
      channelIds: convertScreenChannelsValue(formChannelIds),
      statisticsEnabled,
      identifier,
      locationId,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      content,
      minItems: convertAutoselectValueNumber(minItems),
      notes,
      optimization
    }
    if (screen.playlistId && screen.channelIds) {
      screen.playlistPriority = playlistPriority
    } else {
      screen.playlistPriority = null
    }
    if (screen.playlistId) {
      const { environmentId: playlistEnvironmentId } = yield select(
        selectPlaylistById(screen.playlistId)
      )
      screen.playlistEnvironmentId =
        environmentId !== playlistEnvironmentId ? playlistEnvironmentId : null
    } else {
      screen.playlistEnvironmentId = null
    }

    if (tags) {
      const newTags = tags.map(({ label }) => label)
      screen.tags = newTags.length ? newTags : null
    }
    if (permissionTags) {
      const newPermissionTags = permissionTags.map(({ label }) => label)
      screen.permissionTags = newPermissionTags.length ? newPermissionTags : null
    }
    // When changing screen type and playlistId <=> listId, can't have both
    switch (type) {
      case 'media':
        screen.listId = null
        break
      case 'price':
        screen.playlistId = null
        screen.channelIds = null
        break
      default:
        break
    }

    if (defaultMedia) {
      const type = getMediaTypeFromKey(defaultMedia)
      if (type) {
        screen.defaultMedia = {
          key: defaultMedia,
          type
        }
      } else {
        throw new SubmissionError({
          _error: i18n.t('error.screen.invalidDefaultMediaType')
        })
      }
    } else {
      screen.defaultMedia = null
    }

    if (location) {
      const screenLocation: Location = yield call(
        getLocationForScreen,
        location.value,
        location.label
      )
      screen.location = screenLocation
    } else {
      screen.location = null
    }

    const updatedAttributes = yield call(Api.updateScreen, environmentId, screenIds[0], screen)

    yield put(
      updateScreenSuccess({
        ...updatedAttributes,
        screenId: screenIds[0]
      })
    )
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.screen.somethingWrongUpdate')
      })
    )
    yield put(updateScreenFail(error.message))
  }
}

export function* handleUpdateScreenFromDashboard({
  payload: { formData, resolve, reject }
}: handleUpdateScreenFromDashboardParams) {
  try {
    const { screenId, environmentId, configs: newConfigs } = formData
    const {
      rotation,
      name,
      productNumber,
      playlistId,
      type,
      listId,
      channelIds,
      statisticsEnabled,
      identifier,
      billable,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      configs,
      minItems
    }: ScreenResponse = yield select(selectScreenByIdDashboard(screenId))

    // Note: you do not need to add all screen properties here as screens-api 'update' function creates
    // update expressions for only given properties of the document.
    const screen: UpdateScreen = {
      rotation,
      name,
      productNumber,
      channelIds,
      playlistId,
      listId,
      type,
      statisticsEnabled,
      identifier,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      minItems
    }

    // add new ON/OFF-timer if given
    if (newConfigs?.onOffTimer) {
      screen.configs = {
        ...screen.configs,
        onOffTimer: newConfigs.onOffTimer
      }
    }

    const updatedAttributes = yield call(Api.updateScreen, environmentId, screenId, screen)

    yield put(
      updateScreenFromDashboardSuccess({
        ...updatedAttributes,
        screenId
      })
    )
    resolve()
    toast.success(i18n.t('screens.updateScreenFromDashboardSuccess'))
  } catch (error) {
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.screen.somethingWrongUpdate')
      })
    )
    yield put(updateScreenFromDashboardFail(error.message))
  }
}

export function* handleBatchUpdateScreens({
  payload: { formData, resolve, reject }
}: handleBatchUpdateScreensParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const screenIds: string[] = yield select(selectSelectedScreenIds)
    const {
      properties,
      rotation,
      playlistId,
      playlistPriority,
      channelIds: formChannelIds,
      defaultMedia,
      productNumber,
      location,
      type,
      listId,
      tags,
      permissionTags,
      statisticsEnabled,
      locationId,
      billable,
      configs,
      pdaConfigs,
      isTest,
      showBarcode,
      syncPlay,
      content,
      minItems,
      optimization
    } = formData
    const screen: BatchUpdateScreenProperties = {
      rotation: properties.rotation ? rotation : undefined,
      productNumber: properties.productNumber ? productNumber : undefined,
      playlistId: properties.playlistId ? convertAutoselectValue(playlistId) : undefined,
      type: properties.type ? type : undefined,
      listId: properties.listId ? convertAutoselectValue(listId) : undefined,
      channelIds: properties.channelIds ? convertScreenChannelsValue(formChannelIds) : undefined,
      statisticsEnabled: properties.statisticsEnabled
        ? getBooleanPropertyValue(statisticsEnabled)
        : undefined,
      locationId: properties.locationId ? locationId : undefined,
      billable: properties.billable ? getBooleanPropertyValue(billable) : undefined,
      configs: getBatchConfigs(properties, configs),
      pdaConfigs: getBatchPdaConfigs(properties, pdaConfigs),
      isTest: properties.isTest ? getBooleanPropertyValue(isTest) : undefined,
      showBarcode: properties.showBarcode ? getBooleanPropertyValue(showBarcode) : undefined,
      syncPlay: properties.syncPlay ? getBooleanPropertyValue(syncPlay) : undefined,
      content: getBatchContent(properties, content),
      optimization: getBatchOptimization(properties, optimization),
      minItems: properties.minItems ? convertAutoselectValueNumber(minItems) : undefined
    }
    if (screen.playlistId && screen.channelIds) {
      screen.playlistPriority = playlistPriority
    }
    if (screen.playlistId) {
      const { environmentId: playlistEnvironmentId, syncPlay } = yield select(
        selectPlaylistById(screen.playlistId)
      )
      if (syncPlay) {
        // playlist has sync play, must change screen type to support it also
        screen.syncPlay = true
      } else {
        // playlist is not sync play, must change screen to not support it
        screen.syncPlay = false
      }
      screen.playlistEnvironmentId =
        environmentId !== playlistEnvironmentId ? playlistEnvironmentId : null
      // set `media` type for screen since all will have playlist
      screen.type = ScreenType.media
      // set `listId` to null since all are media screens
      screen.listId = null
    } else if (screen.listId) {
      // set `price` type for screen since all will have list
      screen.type = ScreenType.price
      // set `listId` and `channelIds` to null since all are price screens
      screen.playlistId = null
      screen.channelIds = null
    }

    if (properties.tags && tags) {
      screen.tags = tags.map(({ label }) => label)
    }
    if (properties.permissionTags && permissionTags) {
      screen.permissionTags = permissionTags.map(({ label }) => label)
    }

    if (properties.defaultMedia && defaultMedia) {
      const type = getMediaTypeFromKey(defaultMedia)
      if (type) {
        screen.defaultMedia = {
          key: defaultMedia,
          type
        }
      } else {
        throw new SubmissionError({
          _error: i18n.t('error.screen.invalidDefaultMediaType')
        })
      }
    }

    if (properties.location && location) {
      const screenLocation: Location = yield call(
        getLocationForScreen,
        location.value,
        location.label
      )
      screen.location = screenLocation
    }

    if (Object.keys(screen).length === 0) {
      new SubmissionError({
        _error: i18n.t('error.screen.updateBatchPropertiesMissing')
      })
    }

    const { updatedScreens } = yield call(Api.updateBatchScreens, environmentId, screenIds, screen)

    yield put(batchUpdateScreensSuccess(updatedScreens))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    if (error instanceof SubmissionError) {
      yield call(reject, error)
    }
    yield call(
      reject,
      new SubmissionError({
        _error: i18n.t('error.screen.somethingWrongUpdate')
      })
    )
    yield put(updateScreenFail(error.message))
  }
}

interface HandleScreenIdPayload {
  payload: string
}

export function* handleGetScreen({ payload: screenId }: HandleScreenIdPayload) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    if (environmentId) {
      const screen: ScreenResponse = yield call(Api.getScreen, environmentId, screenId)
      yield put(getScreenSuccess(screen))
    } else {
      yield put(getScreenFail(i18n.t('error.screen.envNotSelected')))
    }
  } catch (error) {
    yield put(getScreenFail(error.message))
  }
}

export function* handleGetScreenFromDashboard({
  payload: { screenId, environmentId }
}: {
  payload: { screenId: string; environmentId: string }
}) {
  try {
    const screen: ScreenResponse = yield call(Api.getScreen, environmentId, screenId)
    yield put(getScreenFromDashboardSuccess(screen))
  } catch (error) {
    yield put(getScreenFromDashboardFail(error.message))
  }
}

export function* handleSelectScreen({ payload: screenId }: HandleScreenIdPayload) {
  const selectedScreenIds = yield select(selectSelectedScreenIds)
  if (selectedScreenIds.length === 1) {
    yield put(getScreen(screenId))
  }
}

export function* handleListScreens({ payload }: { payload?: { includeAllStatuses: boolean } }) {
  try {
    // list screens is also called from home page so use getSelectedEnvironmentId that
    // can read environmentId also from localStorage
    const environmentId: string | undefined = yield call(getSelectedEnvironmentId)
    if (environmentId) {
      const screens = yield call(Api.getScreens, environmentId, payload?.includeAllStatuses)
      yield put(listScreensSuccess(screens))
      // selected environment not loaded yet, wait for success then list screens
    } else {
      yield take([getEnvironmentSuccess, getEnvironmentFail])
      const environmentId: string = yield select(selectSelectedEnvironmentId)
      const screens = yield call(Api.getScreens, environmentId)
      yield put(listScreensSuccess(screens))
    }
  } catch (error) {
    yield put(listScreensFail(error.message))
  }
}

/**
 * Admin only function for listing all screens that have warnings
 */
export function* handleListScreensWithWarnings() {
  try {
    const screensWithWarnings: ScreenResponse[] = yield call(Api.listScreensWarnings)
    yield put(listScreensWithWarningsSuccess(screensWithWarnings))
  } catch (error) {
    yield put(listScreensWithWarningsFail(error.message))
  }
}

export function* handleDeleteScreen({ payload: screenId }: HandleScreenIdPayload) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    yield call(Api.deleteScreen, environmentId, screenId)
    yield put(closeDialog())
    yield put(deleteScreenSuccess(screenId))
  } catch (error) {
    yield put(deleteScreenFail(error.message))
  }
}

interface HandleSendScreenCommanParams {
  payload: {
    screenId: string
    command: ScreenIotMessageCommand
  }
}
export function* handleSendScreenCommand({
  payload: { screenId, command }
}: HandleSendScreenCommanParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const screen = yield select(selectScreenById(screenId))
    yield call(Api.sendScreenCommand, environmentId, screenId, command)
    const trasnlatedCommand = i18n.t(`screens.commands.${command}`)
    toast.info(
      i18n.t('screens.commands.toastMessage', { command: trasnlatedCommand, name: screen.name })
    )
    yield put(sendScreenCommandSuccess())
  } catch (error) {
    yield put(sendScreenCommandFail(error.message))
  }
}

export function* handleFetchStatsFile({
  payload: { formData, resolve, reject }
}: handleFetchStatsFilesParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { screenId, screenName, startDate, endDate } = formData
    const presignedUrls: {
      date: string
      url: string | null
    }[] = yield call(
      Api.getStatisticsPresignedUrls,
      environmentId,
      screenId,
      startDate.valueOf(),
      endDate.valueOf()
    )
    const zip = new JSZip()
    for (const dateUrl of presignedUrls) {
      const { date, url } = dateUrl
      if (url) {
        const file = yield call(Api.getScreenStatisticsFile, url)
        const fileName = `${screenName}-${date}.csv`
        zip.file(fileName, file)
      }
    }
    const totalFilesCount = Object.keys(zip.files).length
    if (totalFilesCount === 0) {
      throw new Error('No files found')
    }
    yield call(
      generateAndDownloadZip,
      zip,
      `${screenName}-statistics-${new Date().toISOString()}.zip`
    )
    toast.success(
      i18n.t('screens.downloadStatsSuccess', {
        days: presignedUrls.length,
        files: totalFilesCount
      })
    )
    yield put(fetchStatsFilesSuccess())
    resolve()
  } catch (error) {
    yield put(fetchStatsFilesFail(error.message))
    yield call(reject, new SubmissionError({ _error: i18n.t('error.screen.fetchStatistics') }))
  }
}
export function* handleDownloadScreensCsv() {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { name: environmentName } = yield select(selectEnvironmentById(environmentId))
    const screens: ScreenResponse[] = yield select(selectScreensAsArray)
    const csv = generateScreensCsv(screens)
    downloadCsvFile(csv, `screens_${environmentName}.csv`)
    yield put(downloadScreensCsvSuccess())
  } catch (error) {
    toast.error(i18n.t('errors.screen.downloadCsv'))
    yield put(downloadScreensCsvFail(error.message))
  }
}

export function* handleDownloadAdminScreens() {
  try {
    const { csv } = yield call(Api.fetchAdminScreensCsv)
    const dateNow = new Date().toISOString()
    downloadCsvFile(csv, `SeeSignage_screens_${dateNow}.csv`)
    yield put(downloadAdminScreensCsvSuccess())
  } catch (error) {
    yield put(downloadAdminScreensCsvFail(error.message))
  }
}

export function* handleMoveScreen({
  payload: { formData, resolve, reject }
}: handleMoveScreenParams) {
  try {
    const environmentId: string = yield select(selectEnvironmentIdFromPathname)
    const { screenId, destinationEnvironment } = formData
    yield call(Api.moveScreen, environmentId, screenId, destinationEnvironment.value)
    yield put(moveScreenSuccess(screenId))
    yield put(closeDialog())
    resolve()
  } catch (error) {
    yield call(reject, new SubmissionError({ _error: i18n.t('error.screen.move') }))
    yield put(moveScreenFail(error.message))
  }
}

export function* handleSelectScreenRow({ payload: screenId }: HandleScreenIdPayload) {
  yield put(getScreen(screenId))
}

function* watchEnvironmentsActions() {
  yield all([
    takeLatest(createScreen, handleCreateScreen),
    takeEvery(getScreen, handleGetScreen),
    takeLatest(getScreenFromDashboard, handleGetScreenFromDashboard),
    takeLatest(selectScreen, handleSelectScreen),
    takeLatest(listScreens, handleListScreens),
    takeLatest(listScreensWithWarnings, handleListScreensWithWarnings),
    takeLatest(deleteScreen, handleDeleteScreen),
    takeLatest(updateScreen, handleUpdateScreen),
    takeLatest(updateScreenFromDashboard, handleUpdateScreenFromDashboard),
    takeLatest(batchUpdateScreens, handleBatchUpdateScreens),
    takeLatest(sendScreenCommand, handleSendScreenCommand),
    takeLatest(fetchStatsFiles, handleFetchStatsFile),
    takeLatest(downloadScreensCsv, handleDownloadScreensCsv),
    takeLatest(downloadAdminScreensCsv, handleDownloadAdminScreens),
    takeLatest(moveScreen, handleMoveScreen),
    takeLatest(selectScreenRow, handleSelectScreenRow)
  ])
}

export default [watchEnvironmentsActions]
