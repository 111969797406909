import {
  Card,
  CardActionArea,
  CardContent,
  Tooltip,
  Typography,
  CardHeader,
  CardMedia
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import {
  ScreenResponse,
  checkIfScreenHasOnOffTimer,
  PlaylistUI,
  Channel,
  ListUI
} from '@seesignage/seesignage-utils'
import { deselectScreen, selectScreen } from '../../../actions/screens'
import { selectListById } from '../../../selectors/lists'
import { selectPlaylistById } from '../../../selectors/playlists'
import { DeselectScreen, SelectScreen } from '../../../types/actions'
import { StateInterface } from '../../../types/states'
import { selectChannelById } from '../../../selectors/channels'
import MonitorIcon from '../../../components/Screens/MonitorIcon'
import LastContactDate from './LastContactDate'
import ScreenCardIcons from './ScreenCardIcons'
import ScreenCardChips from './ScreenCardChips'
import ScreenCardContent from './ScreenCardContent'
import ScreenCardStatus from './ScreenCardStatus'

const useStyles = makeStyles(() => ({
  card: {
    width: 250,
    height: 290,
    display: 'inline-block',
    verticalAlign: 'middle',
    margin: '0px 5px 15px',
    cursor: 'pointer',
    userSelect: 'none' as any,
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    '-webkit-user-select': 'none'
  },
  selected: {
    boxShadow: '1px 3px 5px 2px rgba(0, 174, 239, .7)'
  },
  testScreen: {
    backgroundColor: '#cfd8dc'
  },
  cardContent: {
    overflow: 'auto',
    height: 125
  },
  actions: {
    float: 'right' as any
  },
  link: {
    textDecoration: 'none'
  },
  cardMedia: {
    color: '#ffffff',
    textAlign: 'center' as 'center',
    fontWeight: 500
  },
  code: {
    fontFamily: 'Roboto Slab'
  }
}))

interface OwnProps {
  key: string
  screen: ScreenResponse
  selectedScreenIds: string[]
}

interface StateProps {
  playlist?: PlaylistUI
  list?: ListUI
  channel?: Channel
}

interface DispatchProps {
  selectScreen: SelectScreen
  deselectScreen: DeselectScreen
}

type ScreenCardProps = OwnProps & StateProps & DispatchProps & WithTranslation

const ScreenCard: React.FC<ScreenCardProps> = ({
  screen,
  selectScreen,
  deselectScreen,
  selectedScreenIds,
  playlist,
  list,
  channel,
  t
}) => {
  const classes = useStyles()
  const {
    environmentId,
    screenId,
    name,
    statuses,
    channelIds,
    rotation,
    code,
    isTest,
    status,
    syncPlay,
    notes,
    content
  } = screen
  const lastStatusItem = statuses.slice(-1)[0]
  const isSelected = selectedScreenIds.includes(screenId)
  const cardContentProps = {
    t,
    environmentId,
    playlist,
    list,
    channel,
    channelIds
  }
  const screenCardIconsProps = {
    t,
    status: lastStatusItem,
    hasOnOffTimer: checkIfScreenHasOnOffTimer(screen),
    isTest,
    syncPlay,
    notes,
    content
  }
  return (
    <Tooltip disableInteractive title={t('screens.select')}>
      <Card
        className={classNames(classes.card, {
          [classes.selected]: isSelected,
          [classes.testScreen]: isTest
        })}
        onClick={() => {
          isSelected ? deselectScreen(screenId) : selectScreen(screenId)
        }}>
        <CardHeader
          avatar={<MonitorIcon t={t} rotation={rotation} />}
          title={name}
          subheader={<LastContactDate status={status} lastStatusItem={lastStatusItem} />}
        />
        <CardMedia className={classes.cardMedia}>
          <ScreenCardStatus t={t} status={status} />
        </CardMedia>
        <CardActionArea>
          <CardContent className={classes.cardContent}>
            <Typography variant='body1'>
              {t('screens.code')}
              <span className={classes.code}>: {code}</span>
            </Typography>
            <ScreenCardIcons {...screenCardIconsProps} />
            <ScreenCardContent {...cardContentProps} />
            <ScreenCardChips screen={screen} />
          </CardContent>
        </CardActionArea>
      </Card>
    </Tooltip>
  )
}

const mapStateToProps = (
  state: StateInterface,
  { screen: { playlistId, listId, channelIds } }: OwnProps
): StateProps => ({
  playlist: playlistId ? selectPlaylistById(playlistId)(state) : undefined,
  list: listId ? selectListById(listId)(state) : undefined,
  channel: channelIds ? selectChannelById(channelIds[0].channelId)(state) : undefined
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  selectScreen: (screenId: string) => dispatch(selectScreen(screenId)),
  deselectScreen: (screenId: string) => dispatch(deselectScreen(screenId))
})

export default connect<StateProps, DispatchProps, OwnProps, StateInterface>(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ScreenCard))
