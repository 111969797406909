import { Button, DialogActions, Typography, Grid, LinearProgress } from '@mui/material'
import React from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import { WithTranslation, withTranslation } from 'react-i18next'
import { reduxForm, Field, InjectedFormProps } from 'redux-form'
import { fetchStatsFiles } from '../../../actions/screens'
import { CloseDialog } from '../../../types/actions'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { ReduxDatePicker } from '../../../components/FormInput/ReduxWrappers'
import { required } from '../../../validation'
import { validateDownloadScreenStatsForm } from '../../../validation/forms'

interface OwnProps {
  closeDialog: CloseDialog
  dialogId: string
}

interface DispatchProps {
  fetchStats: (formData: any) => void
}

type ComponentProps = OwnProps & DispatchProps

type DownloadScreenStatsProps = ComponentProps & WithTranslation

const DownloadScreenStatsForm: React.FC<DownloadScreenStatsProps &
  InjectedFormProps<{}, ComponentProps>> = ({
  closeDialog,
  dialogId,
  submitting,
  fetchStats,
  handleSubmit,
  error,
  t
}) => (
  <form onSubmit={handleSubmit(fetchStats)}>
    {submitting && <LinearProgress />}
    <Grid container spacing={4}>
      <Grid item xs={6}>
        <Field
          name='startDate'
          component={ReduxDatePicker}
          label={t('date.startDate')}
          required
          validate={[required]}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={6}>
        <Field
          name='endDate'
          component={ReduxDatePicker}
          label={t('date.endDate')}
          required
          validate={[required]}
          maxDate={new Date()}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption'>{t('screens.fetchStatsHelper')}</Typography>
      </Grid>
    </Grid>
    <Typography variant='body1' color='error'>
      {error || ''}
    </Typography>
    <DialogActions>
      <Button onClick={() => closeDialog(dialogId)} color='primary'>
        {t('general.cancel')}
      </Button>
      <Button disabled={submitting} color='primary' type='submit'>
        {t('general.download')}
      </Button>
    </DialogActions>
  </form>
)

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  fetchStats: bindSubmitActionToPromise(dispatch, fetchStatsFiles)
})
export default connect(
  null,
  mapDispatchToProps
)(
  reduxForm<{}, ComponentProps>({
    form: 'DownloadScreenStatsForm',
    validate: validateDownloadScreenStatsForm
  })(withTranslation()(DownloadScreenStatsForm))
)
