import React, { Fragment } from 'react'
import {
  PlaylistUI,
  List,
  Channel,
  AutocompleteOption,
  EnvironmentScreenFeatures
} from '@seesignage/seesignage-utils'
import { InjectedFormProps, Field, reduxForm, formValueSelector } from 'redux-form'
import { Grid, MenuItem, Typography, DialogActions, Button } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import SimpleExtensionPanel from '../../../components/ExtensionPanels/SimpleExtensionPanel'
import {
  ReduxTextField,
  ReduxGoogleAutocomplete,
  ReduxSwitch,
  ReduxCreatableAutocomplete
} from '../../../components/FormInput/ReduxWrappers'
import { validateOnOffTimers } from '../../../validation'
import { selectEnvironmentIdFromPathname } from '../../../selectors/routing'
import { selectAllPlaylistsAsArray } from '../../../selectors/playlists'
import { selectAllChannelsAsArray } from '../../../selectors/channels'
import { compareStrings } from '../../../utils/sorting'
import { selectOwnListsAsArray } from '../../../selectors/lists'
import { selectScreensDefaultLocations } from '../../../selectors/screens'
import {
  selectCurrentEnvironmentPermissionTagsAsOptions,
  selectCurrentEnvironmentTagsAsOptions,
  selectEnvironmentScreenFeatures
} from '../../../selectors/environments'
import { selectIsUserDeviceMobile, isUserSystemAdmin, isUserAdmin } from '../../../selectors/users'
import { bindSubmitActionToPromise } from '../../../utils/forms'
import { batchUpdateScreens } from '../../../actions/screens'
import { CloseDialog } from '../../../types/actions'
import ErrorMessage from '../../../components/Errors/ErrorMessage'
import SelectMultipleMediaField from '../../../components/FormInput/SelectMedia/SelectMultipleMediaField'
import OnOffTimerField from './Fields/OnOffTimerField'
import ContentSelectionField, { ContentSelectionFieldProps } from './Fields/ContentSelectionField'
import ScreenTypeField from './Fields/ScreenTypeField'
import ActivateField from './Fields/ActivateField'

const useStyles = makeStyles(theme => ({
  formContainer: {
    marginBottom: theme.spacing()
  },
  dialogActionsMobile: {
    justifyContent: 'center'
  },
  title: {
    width: '100%',
    padding: '8px 0px 0px 8px'
  },
  paper: {
    width: '98%',
    padding: '8px 8px 12px 8px',
    marginBottom: 8
  }
}))

interface OwnProps {
  closeDialog: CloseDialog
  dialogId: string
}

interface StateProps {
  environmentId?: string
  type: string
  playlists: PlaylistUI[]
  lists: List[]
  channels: Channel[]
  existingLocations: AutocompleteOption[]
  existingTags: AutocompleteOption[]
  permissionTagsAsOptions: AutocompleteOption[]
  isMobile: boolean
  isSystemAdmin: boolean
  environmentScreenFeatures?: EnvironmentScreenFeatures
}

type ComponentProps = OwnProps

const formSelector = formValueSelector('BatchupdateScreensForm')

const BatchupdateScreensForm: React.FC<ComponentProps & InjectedFormProps<{}, ComponentProps>> = ({
  handleSubmit,
  submitting,
  error,
  closeDialog,
  dialogId,
  form
}) => {
  const [t] = useTranslation()
  const dispatch = useDispatch()
  const submitAction = bindSubmitActionToPromise(dispatch, batchUpdateScreens)

  const selectedProperties = useSelector(state => formSelector(state, 'properties'))
  // if screen has sync play property allow adding only sync playlists or sync publishing channels to the screen
  const isSyncPlay = useSelector(state => formSelector(state, 'syncPlay'))
  const type = useSelector(state => formSelector(state, 'type')) || 'media'
  const environmentId = useSelector(selectEnvironmentIdFromPathname)
  const lists = useSelector(selectOwnListsAsArray(environmentId)).sort(compareStrings('name'))
  const existingLocations = useSelector(selectScreensDefaultLocations)
  const existingTags = useSelector(selectCurrentEnvironmentTagsAsOptions)
  const permissionTagsAsOptions = useSelector(selectCurrentEnvironmentPermissionTagsAsOptions)
  const environmentScreenFeatures = useSelector(selectEnvironmentScreenFeatures(environmentId))
  const isSystemAdmin = useSelector(isUserSystemAdmin)
  const isAdmin = useSelector(isUserAdmin)
  const isMobile = useSelector(selectIsUserDeviceMobile)
  const allPlaylists = useSelector(selectAllPlaylistsAsArray)
  const allChannels = useSelector(selectAllChannelsAsArray)

  const filteredPlaylists = isSyncPlay
    ? allPlaylists.filter(({ syncPlay }) => syncPlay)
    : allPlaylists

  const filteredChannels = isSyncPlay ? allChannels.filter(({ syncPlay }) => syncPlay) : allChannels

  const playlists = filteredPlaylists.sort(compareStrings('name'))
  const channels = filteredChannels.sort(compareStrings('name'))

  const classes = useStyles()
  const contentSelectionProps: ContentSelectionFieldProps = {
    environmentId,
    type,
    playlists,
    lists,
    channels,
    t,
    isSystemAdmin,
    isAdmin,
    isBatchUpdate: true
  }
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Typography style={{ textAlign: 'center', padding: 8 }}>
        {t('screens.batchUpdateText')}
      </Typography>
      <SimpleExtensionPanel title={t('screens.createScreenForm.screenInformation')}>
        <Grid container spacing={2}>
          {isSystemAdmin && (
            <Fragment>
              <Grid item xs={1}>
                <ActivateField name='productNumber' />
              </Grid>
              <Grid item xs={11} sm={5}>
                <Field
                  fullWidth
                  disabled={!selectedProperties?.productNumber}
                  id='productNumber'
                  name='productNumber'
                  label={t('screens.createScreenForm.productNo')}
                  component={ReduxTextField}
                />
              </Grid>
            </Fragment>
          )}
          <Grid item xs={1}>
            <ActivateField name='location' />
          </Grid>
          <Grid item xs={11} sm={5}>
            <Field
              fullWidth
              disabled={!selectedProperties?.location}
              name='location'
              label={t('location.location')}
              existingLocations={existingLocations}
              component={ReduxGoogleAutocomplete}
            />
          </Grid>
          {isSystemAdmin && (
            <Fragment>
              <Grid item xs={1}>
                <ActivateField name='locationId' />
              </Grid>
              <Grid item xs={11} sm={5}>
                <Field
                  fullWidth
                  name='locationId'
                  disabled={!selectedProperties?.locationId}
                  label={t('screens.locationId')}
                  helperText={t('screens.locationIdHelper')}
                  component={ReduxTextField}
                />
              </Grid>
            </Fragment>
          )}
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.configurations')}>
        <Grid container spacing={2}>
          <Grid item xs={1}>
            <ActivateField name='rotation' />
          </Grid>
          <Grid item xs={5}>
            <Field
              id='rotation'
              label={t('screens.screenOrientation')}
              name='rotation'
              disabled={!selectedProperties?.rotation}
              component={ReduxTextField}
              fullWidth
              select>
              <MenuItem value={0}>{t('screens.rotation.0')}</MenuItem>
              <MenuItem value={90}>{t('screens.rotation.90')}</MenuItem>
              <MenuItem value={270}>{t('screens.rotation.270')}</MenuItem>
            </Field>
          </Grid>
          <Grid item xs={1}>
            <ActivateField name='volume' />
          </Grid>
          <Grid item xs={5}>
            <Field
              id='volume'
              label={t('screens.createScreenForm.volume')}
              name='configs.volume'
              disabled={!selectedProperties?.volume}
              component={ReduxTextField}
              fullWidth
              type='number'
              inputProps={{ min: 0, max: 100, step: 1 }}
              parse={(value: any) => (!value ? null : Number(value))}
            />
          </Grid>
          {environmentScreenFeatures?.statistics && (
            <Fragment>
              <Grid item xs={1}>
                <ActivateField name='statisticsEnabled' />
              </Grid>
              <Grid item xs={5}>
                <Field
                  disabled={!selectedProperties?.statisticsEnabled}
                  name='statisticsEnabled'
                  component={ReduxSwitch}
                  label={t('screens.statisticsEnabled')}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.statisticsHelper')}
                </Typography>
              </Grid>
            </Fragment>
          )}
          <Grid item xs={1}>
            <ActivateField name='safetyLock' />
          </Grid>
          <Grid item xs={5}>
            <Field
              disabled={!selectedProperties?.safetyLock}
              name='configs.security.safetyLock'
              component={ReduxSwitch}
              label={t('screens.createScreenForm.safetyLock')}
            />
            <Typography display='block' variant='caption'>
              {t('screens.createScreenForm.safetyLockHelper')}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <ActivateField name='ecoSensor' />
          </Grid>
          <Grid item xs={5}>
            <Field
              disabled={!selectedProperties?.ecoSensor}
              name='configs.ecoSensor.isOn'
              component={ReduxSwitch}
              label={t('screens.createScreenForm.ecoSensor')}
            />
            <Typography display='block' variant='caption'>
              {t('screens.createScreenForm.ecoSensorHelper')}
            </Typography>
          </Grid>
          {isSystemAdmin && (
            <Fragment>
              <Grid item xs={1}>
                <ActivateField name='showBarcode' />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name='showBarcode'
                  disabled={!selectedProperties?.showBarcode}
                  component={ReduxSwitch}
                  label={t('screens.showBarcode')}
                />
                <Typography display='block' variant='caption'>
                  {t('screens.showBarcodeHelper')}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <ActivateField name='billable' />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name='billable'
                  disabled={!selectedProperties?.billable}
                  component={ReduxSwitch}
                  label={t('screens.billable')}
                />
              </Grid>
              <Grid item xs={1}>
                <ActivateField name='isTest' />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name='isTest'
                  component={ReduxSwitch}
                  disabled={!selectedProperties?.isTest}
                  label={t('screens.createScreenForm.isTest')}
                />
              </Grid>
              <Grid item xs={1}>
                <ActivateField name='reduceServerLoadIot' />
              </Grid>
              <Grid item xs={5}>
                <Field
                  name='optimization.reduceServerLoadIot'
                  component={ReduxSwitch}
                  disabled={!selectedProperties?.reduceServerLoadIot}
                  label={t('screens.createScreenForm.reduceServerLoadIot')}
                />
              </Grid>
              {environmentScreenFeatures?.syncPlay && (
                <Fragment>
                  <Grid item xs={1}>
                    <ActivateField name='syncPlay' />
                  </Grid>
                  <Grid item xs={5}>
                    <Field
                      disabled={!selectedProperties?.syncPlay}
                      name='syncPlay'
                      component={ReduxSwitch}
                      label={t('screens.createScreenForm.syncPlay')}
                    />
                    <Typography display='block' variant='caption'>
                      {t('screens.createScreenForm.syncPlayHelper')}
                    </Typography>
                  </Grid>
                </Fragment>
              )}
            </Fragment>
          )}
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.defaultMedia')}>
        <div>
          <ActivateField name='defaultMedia' />
          <Typography variant='caption'>
            {t('screens.createScreenForm.defaultMediaHelper')}
          </Typography>
          <Field
            isSingleFileInput
            formName={form}
            name='defaultMedia'
            component={SelectMultipleMediaField}
            disabled={!selectedProperties?.defaultMedia}
          />
        </div>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.content')}>
        <Grid container spacing={2}>
          {environmentScreenFeatures?.types && (
            <Fragment>
              <Grid item xs={1}>
                <ActivateField name='type' />
              </Grid>
              <Grid item xs={11}>
                <Field
                  id='type'
                  label={t('screens.type')}
                  disabled={!selectedProperties?.type}
                  environmentScreenTypes={environmentScreenFeatures.types}
                  name='type'
                  component={ScreenTypeField}
                  isMobile={isMobile}
                  t={t}
                />
              </Grid>
            </Fragment>
          )}
          {type && <ContentSelectionField {...contentSelectionProps} />}
        </Grid>
      </SimpleExtensionPanel>
      <SimpleExtensionPanel title={t('screens.createScreenForm.timers')}>
        <ActivateField name='onOffTimer' />
        <OnOffTimerField disabled={!selectedProperties?.onOffTimer} t={t} classes={classes} />
      </SimpleExtensionPanel>
      {isSystemAdmin && (
        <SimpleExtensionPanel title={t('screens.createScreenForm.tags')}>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <ActivateField name='tags' />
            </Grid>
            <Grid item xs={11}>
              <div>
                <Field
                  name='tags'
                  id='tags'
                  disabled={!selectedProperties?.tags}
                  placeholder={t('screens.addTags')}
                  component={ReduxCreatableAutocomplete}
                  label={t('screens.tags')}
                  options={existingTags}
                  multiple
                />
                <Typography variant='caption'>{t('screens.tagsHelper')}</Typography>
              </div>
            </Grid>
            <Grid item xs={1}>
              <ActivateField name='permissionTags' />
            </Grid>
            <Grid item xs={11}>
              <div>
                <Field
                  id='permissionTags'
                  name='permissionTags'
                  disabled={!selectedProperties?.permissionTags}
                  placeholder={t('screens.addTags')}
                  component={ReduxCreatableAutocomplete}
                  label={t('screens.permissionTags')}
                  options={permissionTagsAsOptions}
                  multiple
                />
                <Typography variant='caption'>{t('screens.permissionTagHelper')}</Typography>
              </div>
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      )}

      {isSystemAdmin && (
        <SimpleExtensionPanel title={t('screens.createScreenForm.pdaConfigurations')}>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <ActivateField name='allowOnlyEditLists' />
            </Grid>
            <Grid item xs={11}>
              <Field
                name='pdaConfigs.allowOnlyEditLists'
                component={ReduxSwitch}
                disabled={!selectedProperties?.allowOnlyEditLists}
                label={t('screens.createScreenForm.allowOnlyEditLists')}
              />
              <Typography display='block' variant='caption'>
                {t('screens.createScreenForm.allowOnlyEditListsHelper')}
              </Typography>
            </Grid>
          </Grid>
        </SimpleExtensionPanel>
      )}
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12} sm={12}>
          <ErrorMessage message={error} />
        </Grid>
      </Grid>
      <DialogActions className={isMobile ? classes.dialogActionsMobile : undefined}>
        <Button onClick={() => closeDialog(dialogId)} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting || !selectedProperties} color='primary' type='submit'>
          {t('general.update')}
        </Button>
      </DialogActions>
    </form>
  )
}

const validate = (values: any) => {
  const errors: any = {}
  return validateOnOffTimers(errors, values)
}

export default reduxForm<{}, ComponentProps>({
  form: 'BatchupdateScreensForm',
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  updateUnregisteredFields: true,
  validate
})(BatchupdateScreensForm)
