export enum BreadcrumbContentType {
  campaigns = 'campaigns',
  channels = 'channels',
  connections = 'connections',
  environments = 'environments',
  home = 'home',
  infopages = 'infopages',
  integrations = 'integrations',
  lists = 'lists',
  media = 'media',
  playlists = 'playlists',
  products = 'products',
  profile = 'profile',
  screens = 'screens',
  templates = 'templates'
}
