import React from 'react'
import { Field, FieldArray, reduxForm, InjectedFormProps } from 'redux-form'
import DialogActions from '@mui/material/DialogActions'
import makeStyles from '@mui/styles/makeStyles'
import Button from '@mui/material/Button'
import LinearProgress from '@mui/material/LinearProgress'
import SyncIcon from 'mdi-react/RayStartVertexEndIcon'
import AccessPointIcon from 'mdi-react/AccessPointIcon'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import {
  Paper,
  InputAdornment,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Collapse,
  Divider
} from '@mui/material'
import QualityHighIcon from 'mdi-react/QualityHighIcon'
import Video4kBoxIcon from 'mdi-react/Video4kBoxIcon'
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon'
import ChartLineIcon from 'mdi-react/ChartLineIcon'
import { useSelector } from 'react-redux'
import { required, isPriceValid, maxStorageSize, greaterThanZero } from '../../../validation'
import { ReduxTextField, ReduxSwitch } from '../../../components/FormInput/ReduxWrappers'
import { CreateEnvironmentFormData } from '../../../types/environments'
import ReduxSelectEnvironmentColors from '../../../components/FormInput/ReduxWrappers/ReduxSelectEnvironmentColors'
import { selectParentEnviromentColors } from '../../../selectors/environments'
import ErrorMessage from '../../../components/Errors/ErrorMessage'

const parseStorage = (value?: string) =>
  value !== '' && value !== undefined && value !== null ? Math.round(parseFloat(value)) : null

const variants = (t: TFunction) => [
  {
    value: 'max500',
    label: '500px'
  },
  {
    value: 'max1000',
    label: '1000px'
  },
  { value: 'original', label: t('media.original') }
]

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: 6
  },
  contentText: {
    marginTop: 12
  },
  paper: {
    margin: '4px 0px 4px 0px'
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  paddingZero: {
    padding: 0
  }
}))

const CREATE_ENVIRONMENT_FORM_NAME = 'CreateEnvironmentForm'

interface SectionProps {
  t: TFunction
}

const BasicDetails: React.FC<SectionProps> = ({ t }) => (
  <List subheader={<ListSubheader disableSticky>{t('environment.details')}</ListSubheader>}>
    <ListItem>
      <Field
        fullWidth
        name='name'
        label={t('environment.name')}
        component={ReduxTextField}
        required
        validate={[required]}
      />
    </ListItem>
    <ListItem>
      <Field
        fullWidth
        name='displayName'
        label={t('environment.displayName')}
        component={ReduxTextField}
      />
    </ListItem>
    <ListItem>
      <Field
        name='pricing.perScreen'
        fullWidth
        component={ReduxTextField}
        type='number'
        validate={[isPriceValid]}
        InputProps={{
          inputProps: { min: 0, max: 10000, step: 0.01 },
          endAdornment: <InputAdornment position='end'>€</InputAdornment>
        }}
        label={t('environment.pricing.perScreen')}
      />
    </ListItem>
    <ListItem>
      <Field
        name='features.storageSize'
        fullWidth
        component={ReduxTextField}
        type='number'
        parse={parseStorage}
        label={t('environment.settingsForm.storageSize')}
        InputProps={{
          inputProps: { min: 1, max: 100, step: 1 }
        }}
        required
        validate={[greaterThanZero, maxStorageSize]}
      />
    </ListItem>
  </List>
)

const VideoUploads: React.FC<SectionProps> = ({ t }) => (
  <List subheader={<ListSubheader disableSticky>{t('media.videos')}</ListSubheader>}>
    <ListItem>
      <ListItemIcon>
        <QualityHighIcon color='red' />
      </ListItemIcon>
      <ListItemText primary='High Quality video uploads' />
      <ListItemSecondaryAction>
        <Field name='features.hqEnabled' component={ReduxSwitch} />
      </ListItemSecondaryAction>
    </ListItem>
    <Divider />
    <ListItem>
      <ListItemIcon>
        <Video4kBoxIcon color='blue' />
      </ListItemIcon>
      <ListItemText primary='4K video uploads' />
      <ListItemSecondaryAction>
        <Field name='features.uhdEnabled' component={ReduxSwitch} />
      </ListItemSecondaryAction>
    </ListItem>
  </List>
)

const ImageUploads: React.FC<SectionProps> = ({ t }) => (
  <List subheader={<ListSubheader disableSticky>{t('media.images')}</ListSubheader>}>
    {variants(t).map(({ label, value }) => (
      <ListItem key={label}>
        <ListItemText primary={label} />
        <ListItemSecondaryAction>
          <Field name={`features.imageVariants.${value}`} key={label} component={ReduxSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
    ))}
  </List>
)

interface ScreenFeaturesProps extends SectionProps {
  classes: Record<string, string>
}

const ScreensFeatures: React.FC<ScreenFeaturesProps> = ({ classes, t }) => (
  <List subheader={<ListSubheader disableSticky>{t('navigation.screens')}</ListSubheader>}>
    <ListItem>
      <ListItemText primary={t('screens.type')} />
    </ListItem>
    <Collapse in={true}>
      <List component='div' disablePadding>
        <ListItem className={classes.nested}>
          <ListItemIcon>
            <CurrencyUsdIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('environment.createEnvironmentForm.features.screens.priceScreen')}
          />
          <ListItemSecondaryAction>
            <Field name='features.screens.types.price' component={ReduxSwitch} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Collapse>
    <ListItem>
      <ListItemText primary={t('screens.createScreenForm.configurations')} />
    </ListItem>
    <Collapse in={true}>
      <List component='div' disablePadding>
        <ListItem>
          <ListItemIcon>
            <SyncIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('environment.createEnvironmentForm.features.screens.syncPlay')}
          />
          <ListItemSecondaryAction>
            <Field name='features.screens.syncPlay' component={ReduxSwitch} />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <ChartLineIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('environment.createEnvironmentForm.features.screens.statistics')}
          />
          <ListItemSecondaryAction>
            <Field name='features.screens.statistics' component={ReduxSwitch} />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon>
            <AccessPointIcon />
          </ListItemIcon>
          <ListItemText
            primary={t('environment.createEnvironmentForm.features.screens.reduceServerLoadIot')}
          />
          <ListItemSecondaryAction>
            <Field name='features.screens.reduceServerLoadIot' component={ReduxSwitch} />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </Collapse>
  </List>
)

const OtherFeatures: React.FC<SectionProps> = ({ t }) => (
  <>
    <List subheader={<ListSubheader disableSticky>{t('environment.features')}</ListSubheader>}>
      <ListItem>
        <ListItemText primary={t('navigation.campaigns')} />
        <ListItemSecondaryAction>
          <Field name='features.campaigns' component={ReduxSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
      <ListItem>
        <ListItemText primary={t('navigation.lists')} />
        <ListItemSecondaryAction>
          <Field name='features.lists' component={ReduxSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
    <List subheader={<ListSubheader disableSticky>{t('navigation.infopages')}</ListSubheader>}>
      <ListItem>
        <ListItemText primary={t('environment.infopages.proFeatures')} />
        <ListItemSecondaryAction>
          <Field name='features.infopages.proFeatures' component={ReduxSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
    <List subheader={<ListSubheader disableSticky>{t('navigation.channels')}</ListSubheader>}>
      <ListItem>
        <ListItemText primary={t('environment.channels.importScreenCodes')} />
        <ListItemSecondaryAction>
          <Field name='features.channels.importScreenCodes' component={ReduxSwitch} />
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  </>
)

const Colors: React.FC<{ t: TFunction; isUpdate: boolean }> = ({ t, isUpdate }) => {
  const parentEnvironementColors = useSelector(selectParentEnviromentColors)
  return (
    <>
      <List
        style={{ padding: 0 }}
        subheader={<ListSubheader disableSticky>{t('environment.colors')}</ListSubheader>}>
        <ListItem>
          <FieldArray
            name='colors'
            component={ReduxSelectEnvironmentColors}
            props={{ editable: true }}
            rerenderOnEveryChange
          />
        </ListItem>
      </List>
      {isUpdate && parentEnvironementColors && parentEnvironementColors.length > 0 && (
        <List
          subheader={<ListSubheader disableSticky>{t('environment.parentColors')}</ListSubheader>}>
          <ListItem>
            <FieldArray
              name='parentColors'
              component={ReduxSelectEnvironmentColors}
              props={{ editable: true }}
              rerenderOnEveryChange
            />
          </ListItem>
        </List>
      )}
    </>
  )
}

const Notes = ({ t }) => (
  <List subheader={<ListSubheader disableSticky>{t('environment.notesSection')}</ListSubheader>}>
    <ListItem>
      <Field
        label={t('environment.systemAdminNotes')}
        name='notes'
        component={ReduxTextField}
        fullWidth
        multiline
        minRows={2}
        maxRows={4}
        isToolbar
      />
    </ListItem>
  </List>
)

interface CreateEnvironmentFormProps {
  submitAction: (formData: any) => void
  submitButtonLabel: string
  closeDialog: () => void
  isUpdate: boolean
}

const CreateEnvironmentForm: React.FC<CreateEnvironmentFormProps &
  InjectedFormProps<CreateEnvironmentFormData, CreateEnvironmentFormProps>> = ({
  handleSubmit,
  submitting,
  submitAction,
  closeDialog,
  submitButtonLabel,
  error,
  isUpdate
}) => {
  const classes = useStyles()
  const [t] = useTranslation()
  return (
    <form onSubmit={handleSubmit(submitAction)}>
      <Paper elevation={3} className={classes.paper}>
        <BasicDetails t={t} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <VideoUploads t={t} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <ImageUploads t={t} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <ScreensFeatures classes={classes} t={t} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <OtherFeatures t={t} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <Colors t={t} isUpdate={isUpdate} />
      </Paper>
      <Paper elevation={3} className={classes.paper}>
        <Notes t={t} />
      </Paper>
      {submitting && <LinearProgress />}
      <ErrorMessage message={error} />
      <DialogActions>
        <Button onClick={closeDialog} color='primary'>
          {t('general.cancel')}
        </Button>
        <Button disabled={submitting} color='primary' type='submit'>
          {submitButtonLabel}
        </Button>
      </DialogActions>
    </form>
  )
}
export default reduxForm<CreateEnvironmentFormData, CreateEnvironmentFormProps>({
  form: CREATE_ENVIRONMENT_FORM_NAME
})(CreateEnvironmentForm)
