import moment from 'moment'
import { validateMediaFile } from '../utils/media'
import i18n from '../translations/i18n'
import { AddMediaFormData } from '../types/media'

/**
 * Custom redux form validate function for  AddMediaForm
 */
const validateAddMediaForm = (formData: AddMediaFormData) => {
  const errors: {
    files?: string[][]
    _error?: string
  } = {}
  if (formData.files && formData.files?.length > 0) {
    const filesErrors = formData.files.map(file =>
      validateMediaFile(file, formData.allowOnlyMediaType)
    )
    if (filesErrors.some(errors => errors.length > 0)) {
      // add errors to redux form only if any error exists for files
      errors.files = filesErrors
    }
  }
  return errors as any
}

const validateDownloadScreenStatsForm = (values: any) => {
  const errors: any = {}
  if (values) {
    const { startDate, endDate } = values
    if (moment(startDate) > moment(endDate)) {
      errors.endDate = i18n.t('error.playlist.scheduleEndDate')
    }
  }
  return errors
}

export { validateAddMediaForm, validateDownloadScreenStatsForm }
