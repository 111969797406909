import { GetSubsribedUsersToNewsResponse } from '@seesignage/seesignage-utils'
import { TFunction } from 'i18next'
import { CsvScreen } from '../types/screens'
import ScreensApi from '../services/api/screens'

export const generateCsvStr = (header: string[], rows: string[][]) => {
  const csvHeader = header.join()
  const csvRows = rows.join('\n')
  const csvStr = `${csvHeader}\n${csvRows}`
  return csvStr
}

export const downloadFile = (url: string, fileName: string) => {
  const hiddenElement = document.createElement('a')
  hiddenElement.href = url
  hiddenElement.target = '_blank'
  hiddenElement.download = fileName
  hiddenElement.click()
}

export const generateScreensCsv = (screens: CsvScreen[]) => {
  const csvHeader = ['Näytön nimi', 'Koodi', 'Suunta', 'Tunniste', 'Malli']
  const csvRows = screens.map(({ name, code, rotation, identifier, productNumber }) => [
    name.replace(/,/g, ''),
    code || '',
    rotation === 0 ? 'vaaka' : 'pysty',
    identifier || '',
    productNumber
  ])
  return generateCsvStr(csvHeader, csvRows)
}

export const downloadCsvFile = (csvStr: string, fileName: string) => {
  const csvURI = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csvStr)
  return downloadFile(csvURI, fileName)
}

export const generateSubscribersToNewsLetterListCsv = (
  subscribersList: GetSubsribedUsersToNewsResponse
) => {
  const csvHeader = ['Name', 'Email']
  const csvRows = subscribersList.map(({ name, email }) => [name, email])
  return generateCsvStr(csvHeader, csvRows)
}

const getCsvSeparator = (header: string) => {
  if (header.includes(',')) {
    return ','
  } else if (header.includes(';')) {
    return ';'
  }
  throw new Error('invalidCsvSeparator')
}

const SCREEN_CODE_COLUMN_NAME = 'Frame ID'

interface CSVRow {
  [column: string]: string
}

const parseCSVData = (csvData: string) => {
  // Split the CSV data into rows
  const lines = csvData.split('\n')
  // Assuming the first row contains headers, extract column names
  const trimmedHeaders = lines[0].trim()
  const separator = getCsvSeparator(trimmedHeaders)
  const headers = lines[0].trim().split(separator)
  // make sure that Frame ID exists
  const hasScreenCodeColumn = headers.some(h => h === SCREEN_CODE_COLUMN_NAME)
  if (!hasScreenCodeColumn) {
    throw new Error('screenCodeColumnMissing')
  }

  // Process each row (skipping the header row)
  const rows = lines.slice(1).reduce((accumulator, currentLine) => {
    const rowValues = currentLine.trim().split(separator)
    // Create an object representing the CSV row
    const row: CSVRow = headers.reduce((obj, header, index) => {
      obj[header] = rowValues[index]
      return obj
    }, {} as CSVRow)

    accumulator.push(row)
    return accumulator
  }, [] as CSVRow[])
  return rows
}

const readCsvFileToRows = (file: File) =>
  new Promise<CSVRow[]>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = e => {
      if (e.target && typeof e.target.result === 'string') {
        try {
          const rows = parseCSVData(e.target.result)
          resolve(rows)
        } catch (error) {
          reject(error)
        }
      }
    }
    reader.onerror = e => {
      // eslint-disable-next-line no-console
      console.error(e)
      reject(new Error('Could not load csv.'))
    }
    reader.readAsText(file)
  })

const filterScreenCodes = (screenCodes: string[]) =>
  [...new Set(screenCodes)].filter(code =>
    code && code.length === 7 ? /^[0-9]{6}[a-zA-Z]$/.test(code) : false
  )

export const readScreenCodesFromCsvAndValidate = async (file: File, environmentId: string) => {
  const rows = await readCsvFileToRows(file)
  const screenCodes = filterScreenCodes(rows.map(row => row[SCREEN_CODE_COLUMN_NAME]))
  if (screenCodes.length === 0) {
    throw new Error('noValidScreenCodes')
  }
  return await ScreensApi.validateScreenCodes(environmentId, screenCodes)
}

export const getImportScreenCodesCsvErrorTranslation = (t: TFunction, errorMessage: string) => {
  if (errorMessage === 'invalidCsvSeparator') {
    return t('channels.forms.screensImportErrors.invalidCsvSeparator')
  } else if (errorMessage === 'noValidScreenCodes') {
    return t('channels.forms.screensImportErrors.noValidScreenCodes')
  } else if (errorMessage === 'screenCodeColumnMissing') {
    return t('channels.forms.screensImportErrors.screenCodeColumnMissing')
  }
  return t('channels.forms.screensImportErrors.unknownError')
}
